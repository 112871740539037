import { _admin, _dewas, _direksi, _kpm, _spi } from "./link"

const access_routes = [
    { type: 'admin', route: _admin },
    { type: 'dewas', route: _dewas },
    { type: 'direksi', route: _direksi },
    { type: 'kpm', route: _kpm },
    { type: 'spi', route: _spi },
]

export default access_routes