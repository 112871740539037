import ArticleController from "../../controller/article/ArticleController"
import KpiIndicatorController from "../../controller/kpi/KpiIndicatorController"
import KpiScoreController from "../../controller/kpi/KpiScoreController"
import KpiVariableController from "../../controller/kpi/KpiVariableController"
import RkapController from "../../controller/rkap/RkapController"

const _spi = [
    // kpi indicator
    { path: 'kpi/indicator/data', element: KpiIndicatorController, view: 'data' },

    // kpi variable
    { path: 'kpi/variable/data', element: KpiVariableController, view: 'data' },

    // kpi score
    { path: 'kpi/score/data', element: KpiScoreController, view: 'data' },

    // rkap
    { path: 'rkap/data', element: RkapController, view: 'data' },

    // article note
    { path: 'article', element: ArticleController, view: 'article' },
    { path: 'article/:code', element: ArticleController, view: 'detail' },
]

export default _spi