import React, { useEffect, useState } from 'react'
import Layouts from '../layouts/Layouts'
import { useDispatch } from 'react-redux'
import { getNoteSlug, getPaginateNote } from '../../model/note/note_m';
import { noimage } from '../images';
import { ArrowRight, ManageSearch } from '@mui/icons-material';
import { Loading, PageData } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Button, InputAdornment, TextField } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';

const Article = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [note, setNote] = useState([])
    const [pagination, setPagination] = useState([])
    const [search, setSearch] = useState({
        name: '', status: ''
    })
    const [loading, setLoading] = useState(false)

    const getData = async(page) => {
        try {
            const data = { paginate: 12, page: page }
            const response = await dispatch(getPaginateNote(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setLoading(false)
                setNote(response.data)
                setPagination(response.pagination)
            }
        } catch (error) {
            
        }
    }

    const getSearch = async(page) => {
        try {
            const data = {name: search.name, page: page}
            const response = await dispatch(getNoteSlug(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setLoading(false)
                setNote(response.data)
                setPagination(response.pagination)
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1)
    }, []);

    const handlePagination = async(_, page) => {
        if (search.status === false) {
            return getData(page)
        } else {
            return getSearch(page)
        }
    }

    const onDetail = async(slug) => {
        return navigate(`${slug}`)
    }

    // search
    const onSearch = async(e) => {
        e.preventDefault()
        if (search.name === '') {
            toast.error('Form Pencarian Wajib Diisi')
        } else {
            setLoading(true)
            setSearch({...search, status: true})
            return getSearch(1)
        }
    }

    const onUnlock = async(e) => {
        e.preventDefault()
        setLoading(true)
        setSearch({...search, status: false})
        return getData(1)
    }

    return (
        <Layouts>
            <Toaster position="top-center" />
            { loading ? <Loading /> : '' }
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h1>BLOG CATATAN DEWAN PENGAWAS</h1>
                </div>
                <div>
                    <form onSubmit={search.status ? onUnlock : onSearch} className="d-flex align-items-center">
                        <div className="me-2">
                            <TextField 
                                size="small"
                                fullWidth
                                placeholder="Cari Catatan"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"> <ManageSearch /> </InputAdornment>
                                }}
                                onChange={(e) => setSearch({...search, name: e.target.value})}
                                disabled={ search.status ? true : false }
                                required
                            />
                        </div>
                        <div>
                            {
                                search.status ? 
                                <Button sx={{padding: '7px 14px'}} variant="contained" color="error" type="submit">
                                    Unlock
                                </Button> : 
                                <Button sx={{padding: '7px 14px'}} variant="contained" color="primary" type="submit">
                                    Cari
                                </Button>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <div className="row gx-5 mt-3" style={{marginRight: 0}}>
                {
                    note.map((data) => (
                        <div className="col-md-3 mb-3" key={data.no} onClick={() => onDetail(data.slug)} style={{cursor: 'pointer'}}>
                            <div className="card">
                                <img src={ data.image ? data.image : noimage } alt="" style={{width: '100%', height: 220, borderTopLeftRadius: 4, borderTopRightRadius: 4}} />
                                <div className="card-body">
                                    <div>
                                        <h3 className="text-uppercase">
                                            {
                                                data.title.length >= 24 ?
                                                `${data.title.substr(0, 24)} . . .` : 
                                                data.title
                                            }
                                        </h3>
                                    </div>
                                    <div className="mb-3">
                                        {
                                            data.short_desc.length >= 110 ? 
                                            `${data.short_desc.substr(0, 110)} . . .` :
                                            data.short_desc
                                        }
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <span className="btn-read"> 
                                            Detail <ArrowRight />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

                <div className="col-md-12 mb-3">
                    <PageData 
                        name={'Catatan'}
                        status={pagination.status}
                        total={pagination.total}
                        page={pagination.page}
                        per_page={pagination.per_page}
                        onChange={handlePagination}
                    />
                </div>
            </div>
        </Layouts>
    )
}

export default Article
