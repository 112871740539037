import React, { useEffect, useState } from 'react'
import Note from '../Note'
import { useDispatch } from 'react-redux'
import { deleteNote, getNote, getNoteSlug } from '../../../model/note/note_m';
import Moment from 'react-moment';
import { Button, InputAdornment, TextField } from '@mui/material';
import { Loading, PageData } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ManageSearch } from '@mui/icons-material';

const NoteData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [note, setNote] = useState([])
    const [pagination, setpagination] = useState([])

    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState({name: '', status: false})
    const [loadingSearch, setLoadingSearch] = useState(false)

    const getData = async(page) => {
        try {
            const response = await dispatch(getNote(page)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setLoadingSearch(false)
                setNote(response.data)
                setpagination(response.pagination)
            }
        } catch (error) {
            
        }
    }

    const getSearch = async(page) => {
        try {
            const data = {name: search.name, page: page}
            const response = await dispatch(getNoteSlug(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setLoadingSearch(false)
                setNote(response.data)
                setpagination(response.pagination)
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1)
    }, [])

    const handlePagination = async(_, page) => {
        if (search.status === false) {
            return getData(page)
        } else {
            return getSearch(page)
        }
    }

    const onDetail = async(slug) => {
        return navigate(`${slug}`)
    }

    const onDelete = async(e, slug) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await dispatch(deleteNote(slug)).unwrap().catch((err) => {})
            if (response.status == 200) {
                toast.success('Catatan Dewas Berhasil Dihapus')
                setTimeout(() => {
                    return window.location.reload()
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    // search
    const onSearch = async() => {
        if (search.name === '') {
            toast.error('Judul Catatan Wqajib Diisi')
        } else {
            setLoadingSearch(true)
            setSearch({...search, status: true})
            return getSearch(1)
        }
    }

    const onUnlock = async() => {
        setLoadingSearch(true)
        setSearch({...search, status:false})
        return getData(1)
    }

    return (
        <Note>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="row align-items-center" style={{marginRight: 0}}>
                        <div className="col-md-8 mb-4">
                            <div style={{lineHeight: 0}} className="mb-1">
                                <h3 className="text-uppercase">Data Catatan Dewan Pengawas</h3>
                                <span>List Data Catatan Dewan Pengawas</span>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex justify-content-end">
                                <div className="d-flex align-items-center">
                                    <div className="me-2">
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Cari Judul"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"> <ManageSearch /> </InputAdornment>
                                            }}
                                            value={search.name}
                                            onChange={(e) => setSearch({...search, name: e.target.value})}
                                            disabled={ search.status ? true : false }
                                            required
                                        />
                                    </div>
                                    <div>
                                        {
                                            search.status ? 
                                            <Button onClick={onUnlock} sx={{padding: '7px 14px'}} variant="contained" color="error" type="button">
                                                Unlock
                                            </Button> : 
                                            <Button onClick={onSearch} sx={{padding: '7px 14px'}} variant="contained" color="primary">
                                                Cari
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive mb-3">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                        <span className="form-control fw-bold">No</span>
                                    </th>
                                    <th style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Tanggal</span>
                                    </th>
                                    <th style={{width: '20%'}}>
                                        <span className="form-control fw-bold">Bidang</span>
                                    </th>
                                    <th style={{width: '20%'}}>
                                        <span className="form-control fw-bold">Dewas</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Judul</span>
                                    </th>
                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loadingSearch ? 
                                    <tr>
                                        <th colSpan={6} className="text-center">LOADING . . . .</th>
                                    </tr> : 
                                    note.map((data) => (
                                        <tr key={data.no}>
                                            <td className="text-center">{ data.no > 9 ? data.no : `0${data.no}` }</td>
                                            <td>
                                                <Moment format="dddd, DD MMMM YYYY">{ data.date }</Moment>
                                            </td>
                                            <td>{ data.bidang }</td>
                                            <td>{ data.author }</td>
                                            <td>{ data.title }</td>
                                            <td className="text-center">
                                                <div className="d-flex">
                                                    <div className="me-2">
                                                        <Button onClick={() => onDetail(data.slug)} size="small" variant="contained" color="success">
                                                            Detail
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <form onSubmit={(e) => onDelete(e, data.slug)}>
                                                            <Button size="small" variant="contained" color="error" type="submit">
                                                                Delete
                                                            </Button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <PageData 
                        name={'Catatan'}
                        status={pagination.status}
                        page={pagination.page}
                        total={pagination.total}
                        per_page={pagination.per_page}
                        onChange={handlePagination}
                    />
                </div>
            </div>
        </Note>
    )
}

export default NoteData
