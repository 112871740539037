import React from 'react'
import UserForm from '../../views/user/form/UserForm'
import UserData from '../../views/user/data/UserData'
import UserDataDetail from '../../views/user/data/UserDataDetail'

function UserController({ view }) {
    if (view === 'form') {
        return (
            <UserForm />
        )
    } else if (view === 'detail') {
        return (
            <UserDataDetail />
        )
    } else {
        return (
            <UserData />
        )
    }
}

export default UserController
