import React, { useEffect, useRef, useState } from 'react'
import Note from '../Note'
import { Autocomplete, Button, TextField } from '@mui/material'
import { Loading, imageResizer } from '../../../utils';
import { useDispatch } from 'react-redux';
import { getBidang } from '../../../model/bidang/bidang_m';
import { storeNote } from '../../../model/note/note_m';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { decode } from 'base-64';

const NoteForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams()
    const code = localStorage.getItem('code')
    const name = localStorage.getItem('name')

    const editorRef = useRef(null)

    const [bidang, setBidang] = useState([])
    const [profile, setProfile] = useState([])

    const getDataBidang = async(page) => {
        try {
            const response = await dispatch(getBidang(page)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setBidang(response.data.map((data) => ({code: data.code, label: data.name})))
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataBidang(1)
    }, [])

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [input, setInput] = useState({
       profile_code: decode(JSON.parse(code)), code_bidang: '', bidang : '', author: decode(JSON.parse(name)), title: '',
       content: '', date: '', short_desc: '', image: ''
    })

    const handleChangeImage = async(e) => {
        if (e.target.value !== '') {
            const file = e.target.files[0]
            const resize = await imageResizer(file, 480, 480);
            setInput({...input, image: resize})
        }
    }

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (editorRef.current) {
                input.content = editorRef.current.getContent()
            } else {
                input.content = ''
            }
            const data = {...input}
            const response = await dispatch(storeNote(data)).unwrap().catch((err) => {})
            if (response.status === 201) {
                toast.success('Catatan Dewas Berhasil Disimpan')
                setTimeout(() => {
                    return window.location.href = `/${username}/note/data`
                }, 1200);
            }

            if (response.status === 400) {
                setLoading(false)
                toast.error('Periksa Kembali Data Anda')
                setError(response.message)
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }
    return (
        <Note>
            { loading ? <Loading /> : '' }
            <form onSubmit={onSubmit} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-9 mb-3">
                        <div className="card" style={{height: 510}}>
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Judul Catatan <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Judul Catatan"
                                        value={input.title}
                                        onChange={(e) => setInput({...input, title: e.target.value})}
                                        required
                                    />
                                    <small className="text-danger">{ error.title ? error.title : '' }</small>
                                </div>
                                <div>
                                    <Editor 
                                        apiKey="n439af4cclgxfvbhrov0gwvas7n41i75iukgza52nizryl7m"
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        init={{
                                            menubar: false,
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                                                'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'wordcount'
                                            ],
                                            toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat',
                                            setup: (editor) => {
                                                editor.on('init', () => {
                                                    editor.getContainer().style.border = '1px solid #00000042';
                                                    editor.getContainer().style.borderRadius = '4px';
                                                })
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Pilih Bidang <span className="text-danger">*</span></label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={bidang}
                                        value={input.bidang}
                                        renderInput={(params) => <TextField {...params} placeholder="Tulis Bidang" required />}
                                        onChange={(_, event) => setInput({...input, code_bidang: event.code, bidang: event.label})}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Dewas <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        value={decode(JSON.parse(name))}
                                        disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Tanggal <span className="text-danger">*</span></label>
                                    <input type="date" className="form-control" value={input.date} onChange={(e) => setInput({...input, date:e.target.value})} required />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Deskripsi Singkat</label>
                                    <textarea className="form-control" placeholder="Tulis Deskripsi Singkat" value={input.short_desc} onChange={(e) => setInput({...input, short_desc: e.target.value})}></textarea>
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Gambar</label>
                                    <input type="file" accept="image/png, image/jpg, image/jpeg" className="form-control" onChange={handleChangeImage} />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Note>
    )
}

export default NoteForm
