import React from 'react'
import Verified from '../../verified/Verified'
import Header from './header/Header'
import { Toaster } from 'react-hot-toast'

const Layouts = ({ children }) => {
    return (
        <Verified>
            <Toaster position="top-center" />
            <div className="page">
                <Header />
                <div className="page-wrapper" style={{backgroundColor: '#ffffff'}}>
                    <div className="container-fluid">
                        <div className="page-body">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        </Verified>
    )
}

export default Layouts
