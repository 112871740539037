import React, { useEffect, useState } from 'react'
import KpiScore from '../KpiScore'
import { useDispatch } from 'react-redux'
import { getDetailKpiScore, updateKpiScore } from '../../../../model/kpi/kpi_score_m';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../../utils';
import { ReplyAll } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';

function handleGrade(grade) {
    if (! Number(grade)) {
        return 0
    } else {
        if (grade > 100) {
            return ''
        } else {
            return parseInt(grade)
        }
    }
}

const KpiScoreDataDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams();
    const url_back = `/${username}/kpi/score/data`

    const [score, setScore] = useState([])
    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState({
        code: '', code_bidang: '', bidang: '', grade_a: '', grade_b: '', grade_c: '', grade_d: '',
        grade_e: '', desc: ''
    })
    
    const getData = async(code) => {
        try {
            const response = await dispatch(getDetailKpiScore(code)).unwrap().catch((err) => {})
            if (response.status === 200) {
                const result = response.data
                setScore(result)
                setInput({
                    code: result.code, bidang: result.bidang, grade_a: result.grade_a, grade_b: result.grade_b,
                    grade_c: result.grade_c, grade_d: result.grade_d, grade_e: result.grade_e, desc: result.desc ? result.desc: ''
                })
            }

            if (response.status === 404) {
                setLoading(true)
                toast.error('Data Yang Anda Cari Tidak Ditemukan')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(code)
    }, [])

    const handleChangeGradeA = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_a: grade})
    }
    const handleChangeGradeB = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_b: grade})
    }
    const handleChangeGradeC = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_c: grade})
    }
    const handleChangeGradeD = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_d: grade})
    }
    const handleChangeGradeE = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_e: grade})
    }

    const onUpdate = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const data = {...input}
            const response = await dispatch(updateKpiScore(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('KPI Skor Berhasil Diperbaharui')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <KpiScore>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">Detail KPI Skor { score.bidang }</h3>
                            <span>Detail Data KPI Skor</span>
                        </div>
                        <div>
                            <span className="text-orange" style={{cursor: 'pointer'}} onClick={() => window.location.href = url_back}>
                                <ReplyAll />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onUpdate} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Bidang <span className="text-danger">*</span></label>
                                    <input type="text" value={input.bidang} disabled className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Grade A ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade A"
                                        value={input.grade_a}
                                        onChange={handleChangeGradeA}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="from-label">Grade B ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade B"
                                        value={input.grade_b}
                                        onChange={handleChangeGradeB}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="from-label">Grade C ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade C"
                                        value={input.grade_c}
                                        onChange={handleChangeGradeC}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Grade D ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade D"
                                        value={input.grade_d}
                                        onChange={handleChangeGradeD}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="from-label">Grade E ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade E"
                                        value={input.grade_e}
                                        onChange={handleChangeGradeE}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="from-label">Keterangan</label>
                                    <textarea style={{height: 140}} value={input.desc} placeholder="Keterangan" onChange={(e) => setInput({...input, desc: e.target.value})} className="form-control"></textarea>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </KpiScore>
    )
}

export default KpiScoreDataDetail
