import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../http";

const initialState = {}

export const getKpiScore = createAsyncThunk('kpi-score/getKpiScore', async(page) => {
    try {
        const response = await http.get(`/kpi/score?page=${page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const storeKpiScore = createAsyncThunk('kpi-score/storeKpiScore', async(data) => {
    try {
        const response = await http.post('/kpi/score/store', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getDetailKpiScore = createAsyncThunk('kpi-score/getDetailKpiScore', async(code) => {
    try {
        const response = await http.get(`/kpi/score/detail/${code}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const updateKpiScore = createAsyncThunk('kpi-score/updateKpiScore', async(data) => {
    try {
        const response = await http.post(`/kpi/score/update/${data.code}`, data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getByCodeBidangKpiScore = createAsyncThunk('kpi-score/getByCodeBidangKpiScore', async(code_bidang) => {
    try {
        const response = await http.get(`/kpi/score/get-by-code-bidang/${code_bidang}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

const kpi_score_m = createSlice({
    name: 'kpi-score',
    initialState
})

export default kpi_score_m.reducer;