import React, { useEffect, useState } from 'react'
import KpiIndicator from '../KpiIndicator'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getKpiIndicator } from '../../../../model/kpi/kpi_indicator_m';
import { PageData } from '../../../../utils';
import { Button } from '@mui/material';

const KpiIndicatorData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [indicator, setIndicator] = useState([])
    const [pagination, setPagination] = useState([])

    const getData = async(page) => {
        try {
            const response = await dispatch(getKpiIndicator(page)).unwrap().catch((err) => {})
            setIndicator(response.data)
            setPagination(response.pagination)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1)
    }, [])

    const handlePagination = (_, page) => {
        return getData(page)
    }

    const onDetail = (code) => {
        return navigate(`${code}`)
    }

    return (
        <KpiIndicator>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">DATA KPI INDIKATOR</h3>
                            <span>List Data KPI Indikator</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <div className="table-responsive mb-3">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                        <span className="form-control fw-bold">No</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Kode Kpi Indikator</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Tahun</span>
                                    </th>
                                    <th style={{width: '45%'}}>
                                        <span className="form-control fw-bold">Nama Bidang</span>
                                    </th>
                                    <th className="text-center" style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Bisnis</span>
                                    </th>
                                    <th className="text-center" style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Non Bisnis</span>
                                    </th>
                                    <th className="table-border-end text-center" style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    indicator.length > 0 ?
                                    indicator.map((data) => (
                                        <tr key={data.no}>
                                            <td className="text-center">
                                                { data.no > 9 ? data.no : `0${data.no}` }
                                            </td>
                                            <td>{ data.code }</td>
                                            <td className="text-center">{ data.tahun }</td>
                                            <td>{ data.bidang }</td>
                                            <td className="text-center">{ data.bisnis } %</td>
                                            <td className="text-center">{ data.non_bisnis } %</td>
                                            <td className="text-center">
                                                <Button onClick={() => onDetail(data.code)} size="small" fullWidth color="success" variant="contained">
                                                    Detail
                                                </Button>
                                            </td>
                                        </tr>
                                    )) : 
                                    <tr>
                                        <th colSpan={6} className="text-center">DATA TIDAK DITEMUKAN</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* pagination */}
                    <PageData 
                        name={'Kpi Indikator'}
                        status={pagination.status}
                        total={pagination.total}
                        page={pagination.page}
                        per_page={pagination.per_page}
                        onChange={handlePagination}
                    />
                </div>
            </div>
        </KpiIndicator>
    )
}

export default KpiIndicatorData
