import React from 'react'
import { Pagination, PaginationItem } from '@mui/material'

function PaginationData({ count, page, per_page, onChange }) {
    const modulo = count % per_page;
    let total;

    if (modulo === 0) {
        total = count / per_page;
    } else {
        total = Math.floor((count / per_page) + 1)
    }
    
    return (
        <Pagination 
            count={total}
            renderItem={(items) => (
                <PaginationItem 
                    {...items}
                    disabled={items.page === page || items.page === 0 || items.page === total + 1 ? true : false}
                />
            )}
            onChange={onChange}
        />
    )
}

function PageData({ name, status, page, total, per_page, onChange }) {
    return (
        <div className="d-flex justify-content-between align-items-center">
            <div>
                <span>Halaman : { page }</span>
            </div>
            {
                status ?
                <PaginationData count={total} page={page} per_page={per_page} onChange={onChange} /> : ''
            }
            <div>
                <span>Total : { total } { name }</span>
            </div>
        </div>
    )
}

export default PageData
