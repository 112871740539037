import { _admin, _dewas, _direksi, _kpm, _spi } from "./menu"

const _route = [
    { code: 'admin', route: _admin },
    { code: 'direksi', route: _direksi },
    { code: 'dewas', route: _dewas },
    { code: 'spi', route: _spi },
    { code: 'kpm', route: _kpm },
]

export default _route