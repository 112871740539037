import React from 'react'
import General from '../../views/screen/general/General'

function GeneralController() {
    return (
        <General />
    )
}

export default GeneralController
