import React, { useState } from 'react'
import { avatar_default, logo } from '../../../images'
import { ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { Dashboard, ReplyAll } from '@mui/icons-material'
import { decode } from 'base-64'

const Header = () => {
    const { username } = useParams();

    const photo = localStorage.getItem('photo', JSON.stringify('photo'));
    const name = localStorage.getItem('name', JSON.stringify('name'));
    const posisi = localStorage.getItem('posisi', JSON.stringify('posisi'))

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <header className="navbar navbar-expand-md navbar-expand-sm navbar-light d-lg-flex d-print-none sticky-top">
            <div className="container-fluid navbar-mobile">
                <div className="navbar-nav navbar-collapse">
                    <div className="nav-item">
                        <div>
                            <a href="">
                                <img src={ logo } alt="" height="40"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="navbar-nav">
                    <div className="nav-item dropdown">
                        <span onClick={handleClick} className="nav-link d-flex lh-1 text-reset p-0" aria-label="Open user menu" style={{cursor: 'pointer'}}>
                            <Tooltip title="Account Setting">
                                <span className="avatar avatar-sm"  aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                                    <img src={ decode(JSON.parse(photo)) !== 'null' ? decode(JSON.parse(photo)) : avatar_default } alt="" className="rounded-circle" style={{width: 30, height: 30}} />
                                </span>
                            </Tooltip>
                            <div className="d-none d-xl-block ps-2">
                                <div>{ decode(JSON.parse(name)) }</div>
                                <div className="mt-1 small text-muted">{ decode(JSON.parse(posisi)) }</div>
                            </div>
                        </span>
                        <Menu
                            elevation={0}
                            sx={{
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                                '& div': {
                                    width: '180px',
                                }
                            }}
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem>
                                <Link to={`/${username}/screen-welcome`}>
                                    <ListItemIcon>
                                        <ReplyAll fontSize="small" sx={{mr: 1}} /> Kembali
                                    </ListItemIcon>
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to={`/${username}/dashboard`}>
                                    <ListItemIcon>
                                        <Dashboard fontSize="small" sx={{mr: 1}} /> Dashboard
                                    </ListItemIcon>
                                </Link>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
