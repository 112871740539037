import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../http";

const initialState = {}

export const getNote = createAsyncThunk('note/getNote', async(page) => {
    try {
        const response = await http.get(`/note?page=${page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getPaginateNote = createAsyncThunk('note/getPaginateNote', async(data) => {
    try {
        const response = await http.get(`/note/paginate/${data.paginate}?page=${data.page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getLimitNote = createAsyncThunk('note/getLimitNote', async() => {
    try {
        const response = await http.get('note/get-limit').catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getNoteSlug = createAsyncThunk('note/getNoteSlug', async(data) => {
    try {
        const response = await http.get(`/note/search-slug/${data.name}?page=${data.page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const storeNote = createAsyncThunk('note/storeNote', async(data) => {
    try {
        const response = await http.post('/note/store', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const detailNote = createAsyncThunk('note/detailNote', async(slug) => {
    try {
        const response = await http.get(`/note/detail/${slug}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const updateNote = createAsyncThunk('note/updateNote', async(data) => {
    try {
        const response = await http.post(`/note/update/${data.slug}`, data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const deleteNote = createAsyncThunk('note/deleteNote', async(slug) => {
    try {
        const response = await http.delete(`/note/delete/${slug}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

const note_m = createSlice({
    name : 'note',
    initialState
})

export default note_m.reducer;