import React, { useEffect, useState } from 'react'
import KpiIndicator from '../KpiIndicator'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getDetailKpiIndicator, updateBobotKpiIndicator } from '../../../../model/kpi/kpi_indicator_m'
import toast from 'react-hot-toast'
import { Loading } from '../../../../utils'
import { ReplyAll } from '@mui/icons-material'
import { Button, TextField } from '@mui/material'

const KpiIndicatorDataDetail = () => {
    const { username, code } = useParams()
    const dispatch = useDispatch()
    const back = `/${username}/kpi/indicator/data`

    const [indicator, setIndicator] = useState([])
    const [variable, setVariable] = useState([])

    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState({
        code: '', bidang: '', bisnis: '', non: '', status: false
    })

    const getData = async() => {
        try {
            const response = await dispatch(getDetailKpiIndicator(code)).unwrap().catch((err) => {})
            if (response.status === 200) {
                const result = response.data;
                setIndicator(result.indicator)
                setVariable(result.variable.map((data) => ({...data, update_bobot: 0, bobot_indicator: 0})))
                setInput({
                    ...input, code: result.indicator.code, bidang: result.indicator.bidang, bisnis: result.indicator.bisnis, non: result.indicator.non_bisnis
                })
            }

            if (response.status === 404) {
                setLoading(true)
                toast.error('Data Yang Anda Cari Tidak Ditemukan')
                setTimeout(() => {
                    return window.location.href = back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const handleChangeBobot = (e) => {
        if (e.target.value !== '') {
            const bobot = parseInt(e.target.value)
            const sisa = 100 - bobot
            if (bobot > 100) {
                setInput({...input, bisnis: 0, non: 100})
            } else {
                setInput({...input, bisnis: bobot, non: sisa})
            }
        } else {
            setInput({...input, bisnis: 0, non: 100})
        }
    }

    const onLock = async(e) => {
        e.preventDefault();
        setInput({...input, status: true})
    }

    const unLock = async(e) => {
        e.preventDefault();
        setInput({...input, status:false})
        const update = variable.map((data) => ({...data, update_bobot: 0}))
        setVariable(update)
    }

    const handleChangeBobotBisnis = (e, code) => {
        if (e.target.value !== '') {
            const bobot = parseInt(e.target.value)
            const update_bobot_non_code = variable.filter(obj => obj.code !== code && obj.status === 'bisnis').reduce((i, j) => i + parseInt(j.update_bobot), 0)
            const updateBobot = variable.map(data => {
                if (data.code === code) {
                    if (bobot + update_bobot_non_code === parseInt(input.bisnis)) {
                        return {...data, update_bobot: bobot, bobot_indicator: parseInt(input.bisnis)}
                    } else if (bobot + update_bobot_non_code > parseInt(input.bisnis)) {
                        toast.error(`Bobot Melebihi Dari ${input.bisnis} %`)
                        return {...data, update_bobot: 0, bobot_indicator: parseInt(input.bisnis)}
                    } else {
                        return {...data, update_bobot: bobot, bobot_indicator: parseInt(input.bisnis)}
                    }
                }
                return data
            })
            setVariable(updateBobot)  
        }
    }

    const handleChangeBobotNonBisnis = (e, code) => {
        if (e.target.value !== '') {
            const bobot = parseInt(e.target.value)
            const update_bobot_non_code = variable.filter(obj => obj.code !== code && obj.status === 'non').reduce((i, j) => i + parseInt(j.update_bobot), 0)
            const updateBobot = variable.map(data => {
                if (data.code === code) {
                    if (bobot + update_bobot_non_code === parseInt(input.non)) {
                        return {...data, update_bobot: bobot, bobot_indicator: parseInt(input.non)}
                    } else if (bobot + update_bobot_non_code > parseInt(input.non)) {
                        toast.error(`Bobot Melebihi Dari ${input.non} %`)
                        return {...data, update_bobot: 0, bobot_indicator: parseInt(input.non)}
                    } else {
                        return {...data, update_bobot: bobot, bobot_indicator: parseInt(input.non)}
                    }
                }
                return data
            })
            setVariable(updateBobot)  
        }
    }

    const bobot_bisnis = variable.filter(obj => obj.status === 'bisnis').reduce((i, j) => i + parseInt(j.update_bobot), 0)
    const bobot_non_bisnis = variable.filter(obj => obj.status === 'non').reduce((i, j) => i + parseInt(j.update_bobot), 0)

    const onUpdate = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const data = {...input, list: {...variable}}
            const response = await dispatch(updateBobotKpiIndicator(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Kpi Indikator Berhasil Diperbaharui')
                setTimeout(() => {
                    return window.location.href = back
                }, 1200);
            }
        } catch (error) {
            
        }
    }
    return (
        <KpiIndicator>
            { loading ? <Loading /> : '' }
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{lineHeight: 0}} className="mb-3">
                                <h3 className="text-uppercase">{ indicator.bidang }</h3>
                                <span>Detail Kpi Indikator</span>
                            </div>
                            <div>
                                <span className="text-orange" style={{cursor: 'pointer'}} onClick={() => window.location.href = back}>
                                    <ReplyAll />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={input.status ? unLock : onLock}>
                                    <div className="mb-3">
                                        <label className="form-label">Kode Kpi Indicator</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Otomatis"
                                            value={input.code}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Nama Bidang</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Otomatis"
                                            value={input.bidang}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Bobot Bisnis ( % )</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            type="number"
                                            placeholder="Tulis Bobot"
                                            value={input.bisnis}
                                            onChange={handleChangeBobot}
                                            inputProps={{
                                                maxLength: 3
                                            }}
                                            required
                                            disabled={ input.status ? true : false }
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Bobot Non Bisnis ( % )</label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Bobot"
                                            value={input.non}
                                            disabled
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        {
                                            input.status ? 
                                            <Button variant="contained" color="error" type="submit">
                                                Unlock
                                            </Button> : 
                                            <Button variant="contained" color="primary" type="submit">
                                                Lock
                                            </Button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={onUpdate}>
                                    <div className="table-responsive mb-3">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                                        <span className="form-control fw-bold">No</span>
                                                    </th>
                                                    <th>
                                                        <span className="form-control fw-bold">Variabel</span>
                                                    </th>
                                                    <th className="text-center" style={{width: '15%'}}>
                                                        <span className="form-control fw-bold">Status</span>
                                                    </th>
                                                    <th className="text-center" style={{width: '10%'}}>
                                                        <span className="form-control fw-bold">Bobot</span>
                                                    </th>
                                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                                        <span className="form-control fw-bold">Update Bobot</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th colSpan={5}>VARIABEL BISNIS</th>
                                                </tr>

                                                {
                                                    input.status ? 
                                                    variable.filter(obj => obj.status === 'bisnis').map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{ index + 1 > 9 ? index + 1 : `0${index + 1}` }</td>
                                                            <td>{ data.name }</td>
                                                            <td className="text-center">
                                                                Bisnis
                                                            </td>
                                                            <td className="text-center">{ data.bobot } %</td>
                                                            <td className="text-center">
                                                                <input type="number" className="form-control" value={data.update_bobot} onChange={(e) => handleChangeBobotBisnis(e, data.code)} />
                                                            </td>
                                                        </tr>
                                                    )) : ''
                                                }

                                                <tr>
                                                    <th colSpan={5}>VARIABEL NON BISNIS</th>
                                                </tr>

                                                {
                                                    input.status ? 
                                                    variable.filter(obj => obj.status === 'non').map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{ index + 1 > 9 ? index + 1 : `0${index + 1}` }</td>
                                                            <td>{ data.name }</td>
                                                            <td className="text-center">
                                                                Non Bisnis
                                                            </td>
                                                            <td className="text-center">{ data.bobot } %</td>
                                                            <td className="text-center">
                                                                <input type="number" className="form-control" value={data.update_bobot} onChange={(e) => handleChangeBobotNonBisnis(e, data.code)} />
                                                            </td>
                                                        </tr>
                                                    )) : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <Button variant="contained" color="success" type="submit" disabled={ bobot_bisnis === parseInt(input.bisnis) && bobot_non_bisnis === parseInt(input.non) ? false : true }>
                                            Update Data
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </KpiIndicator>
    )
}

export default KpiIndicatorDataDetail
