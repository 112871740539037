import React from 'react'
import { NumericFormat } from 'react-number-format';

function CurrentFormat({ currency }) {
    let format;
    let type;
    if (Math.abs(currency) < 1000) {
        format = Math.abs(currency);
        type = ''
    } else if (Math.abs(currency) >= 1000 && Math.abs(currency) < 1000000) {
        format = Math.abs(currency) / 1000
        type = 'RB'
    } else if (Math.abs(currency) >= 1000000 && Math.abs(currency) < 1000000000) {
        format = Math.abs(currency) / 1000000
        type = 'JT'
    } else if (Math.abs(currency) >= 1000000000) {
        format = Math.abs(currency) / 1000000000
        type = 'M'
    }

    return (
        <NumericFormat 
            value={ format.toFixed(2) } 
            allowLeadingZeros 
            thousandSeparator="," 
            valueIsNumericString
            prefix="Rp. "
            displayType="text"
            renderText={(value) => <span>{value} { type }</span>}
        />
    )
}

export default CurrentFormat
