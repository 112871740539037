import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'; 

function getSubtitle(percent){
    return `<span style="font-size: 60px; font-weight: bold"> ${percent}<span style="font-size: 24px">%</span></span>`;
}

const ComparasiChart = ({ target, realisasi }) => {
    let total_realisasi;
    if (realisasi > 0 && target > 0) {
        total_realisasi = (realisasi / target) * 100;
    } else if (target < 1 && realisasi > 0) {
        total_realisasi = 100
    } else {
        total_realisasi = 0
    }

    const options = {
        chart: {
            type: 'pie',
            margin: 0,
            height: 350
        },
        title: {
            text: '',
            align: 'center'
        },
        legend: {
            enabled: true,
            padding: 0,
            margin: 0,
        },
        subtitle: {
            useHTML: true,
            text: getSubtitle(total_realisasi.toFixed(2)),
            floating: true,
            verticalAlign: 'middle',
            y: 15
        },
        tooltip: {
            pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                colorByPoint: true,
                type: 'pie',
                size: '100%',
                innerSize: '90%',
                dataLabels: {
                    enabled: true,
                    crop: false,
                    distance: '-25%',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '14px'
                    },
                    connectorWidth: 0
                }
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true
                },
                showInLegend: true
            }
        },
        colors: ['#4d7fb5', '#fc8135'],
        series: [{
            enableMouseTracking: false,
            animation: {
                duration: 2000
            },
            colorByPoint: true,
            data: [
                { name: 'REALISASI', y: total_realisasi },
                { name: 'RKAP', y: 100 - total_realisasi }
            ]
        }]
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    )
}

export default ComparasiChart
