import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import User from '../User'
import { CameraAlt, ReplyAll } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { getProfileDetail, updateProfileData, updateProfilePhoto } from '../../../model/profile/profile_m'
import toast from 'react-hot-toast'
import { Loading, b64toBlob, imageResizer } from '../../../utils'
import { avatar_default } from '../../images'
import { Button, TextField } from '@mui/material'
import { Camera, CameraResultType } from '@capacitor/camera'
import { decode } from 'base-64'

const UserDataDetail = () => {
    const dispatch = useDispatch()
    const { username, code } = useParams()
    const url_back = `/${username}/user/data`
    const code_user = localStorage.getItem('code');

    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState([])
    const [input, setInput] = useState({
        name: '', username: '', password: '', posisi: '', jabatan: ''
    })
    const [error, setError] = useState([])

    const getData = async() => {
        try {
            const response = await dispatch(getProfileDetail(code)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setUser(response.data)
                setInput({...input, name: response.data.name, username: response.data.username, posisi: response.data.posisi, jabatan: response.data.jabatan})
            }

            if (response.status === 404) {
                setLoading(true)
                toast.error('Data Yang Anda Cari Tidak Ditemukan')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const [picture, setPicture] = useState('')
    const onTakePhoto = async() => {
        try {
            const camera = await Camera.getPhoto({
                quality: 30,
                allowEditing: true,
                resultType: CameraResultType.Base64
            })
            const image = camera.base64String;
            setPicture('data:image/png;base64,' + image);

            const blob = b64toBlob(image, 'image/png');
            const file = new File([blob], 'image.png', {
                type: blob.type,
                lastModified: new Date().getTime()
            })
            
            const resize = await imageResizer(file, 800, 800);
            const data = {code: code, photo: resize}
            const response = await dispatch(updateProfilePhoto(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Foto Profile Berhasil Diperbaharui')
                setTimeout(() => {
                    return window.location.reload()
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const onUpdate = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const data = {code: code, ...input}
            const response = await dispatch(updateProfileData(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Data User Berhasil Diperbaharui')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }

            if (response.status === 400) {
                toast.error('Periksa Kembali Data Anda')
                setError(response.message)
                setLoading(false)
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }

    const myAccount = async(e) => {
        e.preventDefault();
        setLoading(true)
        toast.success('Anda Akan Diarahkan Ke Halaman My Account')
        setTimeout(() => {
            return window.location.href = `/${username}/my-account`
        }, 1200);
    }

    return (
        <User>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">DETAIL { user.name }</h3>
                            <span>Detail Data User</span>
                        </div>
                        <div>
                            <span className="text-orange" style={{cursor: 'pointer'}} onClick={() => window.location.href = url_back}>
                                <ReplyAll />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={decode(JSON.parse(code_user)) === code ? myAccount : onUpdate}>
                <div className="row mt-3" style={{marginRight: 0}}>
                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <img src={ picture ? picture : picture === '' && user.photo ? user.photo : avatar_default } alt="" width={240} height={240} className="rounded-circle" style={{boxShadow: '1px 1px 5px #000000a1'}} />
                                </div>
                                <div className="text-center" style={{marginTop: '-15%', marginBottom: '7%'}}>
                                    <Button onClick={onTakePhoto} size="small" variant="contained" color="success" startIcon={ <CameraAlt /> }>
                                        Update
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <h4 className="text-uppercase">{ user.name }</h4>
                                </div>
                                <div className="text-center">
                                    { user.posisi } <br />
                                    ( { user.jabatan } )
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Kode User</label>
                                    <span className="form-control fw-bold" style={{backgroundColor: '#0000003b'}}>{ code }</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Nama User <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Nama User"
                                        value={input.name}
                                        onChange={(e) => setInput({...input, name: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Posisi</label>
                                    <span className="form-control fw-bold" style={{backgroundColor: '#0000003b'}}>{ user.posisi }</span>
                                </div>
                                <div>
                                    <label className="form-label">Jabatan</label>
                                    <span className="form-control fw-bold" style={{backgroundColor: '#0000003b'}}>{ user.jabatan }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Username <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Username"
                                        required
                                        value={input.username}
                                        onChange={(e) => setInput({...input, username: e.target.value.toLocaleLowerCase().trim()})}
                                    />
                                    <small className="text-danger">{ error.username ? error.username : '' }</small>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">New Password</label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Password Baru"
                                        value={input.password}
                                        onChange={(e) => setInput({...input, password: e.target.value.toLocaleLowerCase().trim()})}
                                    />
                                </div>
                                <div className="d-flex justify-content-end">
                                    {
                                        decode(JSON.parse(code_user)) === code ? 
                                        <Button variant="contained" color="success" type="submit">
                                            My Account
                                        </Button> : 
                                        <Button variant="contained" color="success" type="submit">
                                            Update Data
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </User>
    )
}

export default UserDataDetail
