import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../http";

const initialState = {}

export const getProfile = createAsyncThunk('profile/getProfile', async(page) => {
    try {
        const response = await http.get(`/profile?page=${page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getProfileType = createAsyncThunk('profile/getProfileType', async(type) => {
    try {
        const response = await http.get(`/profile/get-by-type/${type}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const storeProfile = createAsyncThunk('profile/storeProfile', async(data) => {
    try {
        const response = await http.post('/profile/store', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getProfileSlug = createAsyncThunk('profile/getProfileSlug', async(data) => {
    try {
        const response = await http.get(`/profile/get-slug/${data.name}?page=${data.page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const updateProfilePhoto = createAsyncThunk('profile/updateProfilePhoto', async(data) => {
    try {
        const response = await http.post(`/profile/update/photo/${data.code}`, data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const updateProfileData = createAsyncThunk('profile/updateProfileData', async(data) => {
    try {
        const response = await http.post(`/profile/update/data/${data.code}`, data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getProfileDetail = createAsyncThunk('profile/getProfileDetail', async(code) => {
    try {
        const response = await http.get(`/profile/detail/${code}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getProfileUsername = createAsyncThunk('profile/getProfileUsername', async(username) => {
    try {
        const response = await http.get(`/profile/get-username/${username}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

const profile_m = createSlice({
    name: 'profile',
    initialState
})

export default profile_m.reducer;