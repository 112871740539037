import React from 'react'
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"


const BarChart = ({ data }) => {
    const categories = [];
    const rkap = [];
    const realisasi = [];
    data.map((data) => {
        categories.push(data.name)
        rkap.push(parseFloat(data.target))
        realisasi.push(parseFloat(data.realisasi))
    })

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text:  ``,
            align: 'left'
        },
        xAxis: {
            categories: categories,
            crosshair: true,
            accessibility: {
                description: 'RKAP'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Perbandingan Realisasi dan RKAP'
            }
        },
        accessibility: {
            enabled: false
        },
        tooltip: {
            valueSuffix: ' '
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.0f}',
                }
            }
        },
        colors: ['#fe7a15', '#33b58b'],
        series: [
            {
                name: 'RKAP',
                data: rkap
            },
            {
                name: 'REALISASI',
                data: realisasi
            }
        ]
    }
    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    )
}

export default BarChart
