import React, { useEffect, useState } from 'react'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import { Link } from 'react-router-dom';
import access from './access';
import { decode } from 'base-64';

const Sidebar = ({ toggleDrawer, username, toggle, split, logo }) => {

    const type = localStorage.getItem('type');
    const [route, setRoute] = useState([])

    const getRoute = async() => {
        const access_route = access.filter(obj => obj.type === decode(JSON.parse(type)));
        setRoute(access_route[0]['route']);
    }

    useEffect(() => {
        getRoute()
    }, [])

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box sx={{ padding: '1rem 3rem', backgroundColor: '#0000009c' }}>
                <div>
                    <img src={ logo } alt="" />
                </div>
            </Box>
            
            <List>
                {
                    route.length > 0 ? 
                    route.map((data, index) => (
                        <Link to={`/${username}/${data.to}`} key={index}>
                            <ListItem sx={{":hover" : { backgroundColor: '#00000094' }, backgroundColor: split[2] === data.active ? '#00000094' : '' }} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <data.icon sx={{color: '#ffffff'}} />
                                    </ListItemIcon>
                                    <ListItemText primary={data.name} sx={{color: '#ffffff'}} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    )) : ''
                }
            </List>
        </Box>
    );

    return (
        <SwipeableDrawer
            PaperProps={{
                sx: {
                    backgroundColor: "#0000008f"
                }
            }}
            anchor="left"
            open={toggle}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            {list("left")}
        </SwipeableDrawer>
    )
}

export default Sidebar
