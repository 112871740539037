import React, { useEffect, useState } from 'react'
import Rkap from '../Rkap'
import { useDispatch } from 'react-redux'
import { getRkap } from '../../../model/rkap/rkap_m';
import { NumericFormat } from 'react-number-format';
import { PageData } from '../../../utils';

const RkapData = () => {
    const dispatch = useDispatch();

    const [rkap, setRkap] = useState([])
    const [pagination, setPagination] = useState([])

    const getData = async(page) => {
        try {
            const response = await dispatch(getRkap(page)).unwrap().catch((err) => {})
            setRkap(response.data)
            setPagination(response.pagination)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1)
    }, [])

    const handlePagination = (_, page) => {
        return getData(page)
    }
    return (
        <Rkap>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">DATA RKAP Tahun { new Date().getFullYear() }</h3>
                            <span>List Data RKAP</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <div className="table-responsive mb-3">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                        <span className="form-control fw-bold">No</span>
                                    </th>
                                    <th className="text-center" style={{width: '8%'}}>
                                        <span className="form-control fw-bold">Tahun</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Kode RKAP</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Bidang</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Variabel</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Uraian</span>
                                    </th>
                                    <th className="table-border-end">
                                        <span className="form-control fw-bold">Target RKAP</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    rkap.length > 0 ?
                                    rkap.map((data) => (
                                        <tr key={data.no}>
                                            <td className="text-center">
                                                { data.no > 9 ? data.no : `0${data.no}` }
                                            </td>
                                            <td className="text-center">{ data.tahun }</td>
                                            <td>{ data.code }</td>
                                            <td>{ data.bidang }</td>
                                            <td>{ data.variable }</td>
                                            <td>{ data.name }</td>
                                            <td>
                                                <NumericFormat 
                                                    value={ data.target } 
                                                    allowLeadingZeros 
                                                    thousandSeparator="," 
                                                    valueIsNumericString
                                                    prefix="Rp. "
                                                    displayType="text"
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            </td>
                                        </tr>
                                    )) : 
                                    <tr>
                                        <th colSpan={7} className="text-center">DATA TIDAK DITEMUKAN</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* pagination */}
                    <PageData   
                        status={pagination.status}
                        name={'List Data'}
                        total={pagination.total}
                        page={pagination.page}
                        per_page={pagination.per_page}
                        onChange={handlePagination}
                    />
                </div>
            </div>
        </Rkap>
    )
}

export default RkapData
