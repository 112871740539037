import React, { useEffect, useState } from 'react'
import KpiVariable from '../KpiVariable'
import { useDispatch } from 'react-redux'
import { getAllKpiIndicator } from '../../../../model/kpi/kpi_indicator_m'
import { Autocomplete, Button, TextField } from '@mui/material'
import { getIndicatorStatusKpiVariable, updateBobotKpiVariable } from '../../../../model/kpi/kpi_variable_m'
import toast from 'react-hot-toast'
import { Loading } from '../../../../utils'
import { useParams } from 'react-router-dom'

const status = [
    { code: 'bisnis', label: 'Bisnis' },
    { code: 'non', label: 'Non Bisnis' }
]

const KpiVariableEdit = () => {
    const dispatch = useDispatch()
    const { username } = useParams()

    const [indicator, setIndicator] = useState([])
    const [variable, setVariable] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(false)

    const getDataIndicator = async() => {
        try {
            const response = await dispatch(getAllKpiIndicator()).unwrap().catch((err) => {})
            setIndicator(response.data.map((data) => ({
                code: data.code, label: data.bidang, bisnis: data.bisnis, non_bisnis: data.non_bisnis
            })))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataIndicator()
    }, [])

    const [input, setInput] = useState({
        code_indicator: '', bidang: '', bisnis: '', non_bisnis: '', bobot: '', status: '',
        status_label: '', lock: false
    })

    const handleChangeIndicator = (_, event) => {
        if (event) {
            setInput({...input, code_indicator: event.code, bidang: event.label, bisnis: event.bisnis, non_bisnis: event.non_bisnis, status: '', status_label: '', bobot: ''})
        }
    }

    const handleChangeStatus = (_, event) => {
        if (event) {
            if (event.code === 'bisnis') {
                setInput({...input, status: event.code, status_label: event.label, bobot: input.bisnis})
            } else {
                setInput({...input, status: event.code, status_label: event.label, bobot: input.non_bisnis})
            }
        }
    }

    // lock
    const onLockData = async(e) => {
        e.preventDefault();
        setLoadingSearch(true)
        setInput({...input, lock: true})
        try {
            const data = {code_indicator: input.code_indicator, status: input.status}
            const response = await dispatch(getIndicatorStatusKpiVariable(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setLoadingSearch(false)
                setVariable(response.data.map((data) => ({...data, bobot_update: 0})))
            }
        } catch (error) {
            
        }
    }

    const onUnlockData = () => {
        setInput({
            code_indicator: '', bidang: '', bisnis: '', non_bisnis: '', bobot: '', status: '',
            status_label: '', lock: false
        })
        setVariable([])
    }

    // update bobot
    const handleChangeBobot = (e, code) => {
        const bobot = parseInt(e.target.value);
        const nonBobot = variable.filter(obj => obj.code !== code);
        const updateData = variable.map(data => {
            if (data.code === code) {
                if (! Number(bobot)) {
                    toast.error('Bobot Harus Angka')
                } else {
                    if (parseInt(nonBobot[0]['bobot_update']) + bobot > parseInt(input.bobot)) {
                        toast.error(`Bobot Melebihi Dari ${input.bobot}`)
                        return {...data, bobot_update: 0}
                    } else {
                        return {...data, bobot_update: bobot}
                    }
                }
            }
            return data
        })
        setVariable(updateData)
    }

    const total_bobot = variable.reduce((i, j) => i + parseInt(j.bobot_update), 0)

    const onUpdate = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const data = {list: {...variable}}
            const response = await dispatch(updateBobotKpiVariable(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Bobot KPI Variabel Berhasil Diupdate')
                setTimeout(() => {
                    return window.location.href = `/${username}/kpi/variable/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <KpiVariable>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">EDIT BOBOT KPI Variabel</h3>
                            <span>Edit Bobot KPI Variabel</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-3" style={{marginRight: 0}}>
                <div className="col-md-4 mb-3">
                    <form onSubmit={onLockData}>
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Pilih Bidang <span className="text-danger">*</span></label>
                                    <Autocomplete 
                                        disabled={ input.lock ? true : false }
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={indicator}
                                        value={input.bidang}
                                        renderInput={(params) => <TextField {...params} placeholder="Pilih Bidang" required />}
                                        onChange={handleChangeIndicator}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Status <span className="text-danger">*</span></label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={status}
                                        value={input.status_label}
                                        renderInput={(params) => <TextField {...params} placeholder="Pilih Status" required />}
                                        disabled={ input.code_indicator && input.lock === false ? false : input.indicator && input.lock ? true : true }
                                        onChange={handleChangeStatus}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Bobot ( % )</label>
                                    <input type="text" value={input.bobot} disabled placeholder="Otomatis" className="form-control" />
                                </div>
                                <div className="d-flex justify-content-end">
                                    {
                                        input.lock ?
                                        <Button variant="contained" color="error" onClick={onUnlockData}>
                                            Unlock Data
                                        </Button> : 
                                        <Button disabled={ input.status && input.lock === false ? false : true } variant="contained" color="primary" type="submit">
                                            Lock Data
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="col-md-8 mb-3">
                    <form onSubmit={onUpdate}>
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-4">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control fw-bold">No</span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">Bidang</span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">Variabel</span>
                                                </th>
                                                <th className="text-center" style={{width: '10%'}}>
                                                    <span className="form-control fw-bold">Bobot ( % )</span>
                                                </th>
                                                <th className="table-border-end text-center" style={{width: '15%'}}>
                                                    <span className="form-control fw-bold">Update Bobot ( % )</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                loadingSearch === false && variable.length > 0 ? 
                                                    variable.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">
                                                                { index + 1 > 9 ? index + 1 : `0${index + 1}` }
                                                            </td>
                                                            <td>{ data.bidang }</td>
                                                            <td>{ data.name }</td>
                                                            <td className="text-center">{ data.bobot } %</td>
                                                            <td className="text-center">
                                                                <TextField 
                                                                    size="small"
                                                                    fullWidth
                                                                    placeholder="Bobot"
                                                                    value={data.bobot_update}
                                                                    onChange={(e) => handleChangeBobot(e, data.code)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )) : 
                                                loadingSearch === false &&  variable.length === 0 ?
                                                    <tr>
                                                        <th colSpan={5} className="text-center">DATA TIDAK DITEMUKAN</th>
                                                    </tr> : 
                                                loadingSearch ? 
                                                    <tr>
                                                        <th colSpan={5} className="text-center">Loading ...</th>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <Button disabled={ input.lock && variable.length > 0 && total_bobot === parseInt(input.bobot) ? false : true } variant="contained" color="success" type="submit">
                                        Update Bobot
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </KpiVariable>
    )
}

export default KpiVariableEdit
