export const login = [
    {
        title: 'Cara Melakukan Login di Leadership Dashboard',
        short_desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
        iframe: <iframe style={{width: '100%', borderTopLeftRadius: 4, borderTopRightRadius: 4}} src="https://www.youtube.com/embed/zZiZOsut6No?si=TVj8zXsCHeWwM0dI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> ,
        link: ''
    }
]


export const account = [
    
]

export const dashboard = [
    
]

export const bidang = [

]

export const user = [

]

export const kpi = [

]

export const rkap = [

]


export const article = [

]