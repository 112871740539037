import React, { useEffect, useState } from 'react'
import User from '../User'
import { useDispatch } from 'react-redux'
import { getProfile, getProfileSlug } from '../../../model/profile/profile_m';
import { Button, InputAdornment, TextField } from '@mui/material';
import { PersonSearch } from '@mui/icons-material';
import { PageData } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const UserData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [user, setUser] = useState([])
    const [pagination, setPagination] = useState([])
    const [name, setName] = useState('')
    const [search, setSearch] = useState(false)
    const [loading, setLoading] = useState(false)

    const getData = async(page) => {
        try {
            const response = await dispatch(getProfile(page)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setLoading(false)
                setUser(response.data)
                setPagination(response.pagination)
            }
        } catch (error) {
            
        }
    }

    const getSearch = async(page) => {
        try {
            const data = { name: name, page: page }
            const response = await dispatch(getProfileSlug(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setLoading(false)
                setUser(response.data)
                setPagination(response.pagination)
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1)
    }, [])

    const handlePagination = (_, page) => {
        if (search === true) {
            return getSearch(page)
        } else {
            return getData(page)
        }
    }

    const onSearch = async(e) => {
        e.preventDefault();
        setLoading(true)
        setSearch(true)
        return getSearch(1)
    }

    const onUnlock = async() => {
        setSearch(false)
        setLoading(true)
        setName('')
        return getData(1)
    }

    const onDetail = async(code) => {
        return navigate(`${code}`)
    }

    return (
        <User>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="row align-items-center" style={{marginRight: 0}}>
                        <div className="col-md-8 mb-4">
                            <div style={{lineHeight: 0}}>
                                <h3>DAFTAR PENGGUNA LEADERSHIP DASHBOARD</h3>
                                <span>List Daftar Pengguna Leadership Dashboard</span>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="d-flex justify-content-end">
                                <form onSubmit={onSearch}>
                                    <div className="d-flex align-items-center">
                                        <div className="me-2">
                                            <TextField 
                                                size="small"
                                                fullWidth
                                                placeholder="Cari Nama"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"> <PersonSearch /> </InputAdornment>
                                                }}
                                                required
                                                disabled={ search ? true : false }
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            {
                                                search ? 
                                                <Button onClick={onUnlock} sx={{padding: '7px 14px'}} variant="contained" color="error" type="button">
                                                    Unlock
                                                </Button> : 
                                                <Button sx={{padding: '7px 14px'}} variant="contained" color="primary" type="submit">
                                                    Cari
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive mb-3">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                        <span className="form-control fw-bold">No</span>
                                    </th>
                                    <th style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Kode User</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Nama User</span>
                                    </th>
                                    <th style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Posisi</span>
                                    </th>
                                    <th style={{width: '15%'}}>
                                        <span className="form-control fw-bold">Jabatan</span>
                                    </th>
                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? 
                                    <tr>
                                        <th colSpan={6} className="text-center">LOADING . . . .</th>
                                    </tr> : 
                                    user.map((data) => (
                                        <tr key={data.no}>
                                            <td className="text-center">{ data.no > 9 ? data.no : `0${data.no}` }</td>
                                            <td>{ data.code }</td>
                                            <td>{ data.name }</td>
                                            <td>{ data.posisi }</td>
                                            <td>{ data.jabatan }</td>
                                            <td className="text-center">
                                                <Button onClick={() => onDetail(data.code)} size="small" variant="contained" color="success">
                                                    Detail
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <PageData 
                        name={'List User'}
                        status={pagination.status}
                        page={pagination.page}
                        total={pagination.total}
                        per_page={pagination.per_page}
                        onChange={handlePagination}
                    />
                </div>
            </div>
        </User>
    )
}

export default UserData
