import React, { forwardRef, useEffect, useState } from 'react'
import Layouts from '../layouts/Layouts'
import { TabContext, TabPanel } from '@mui/lab';
import { ButtonBase, Tab, Tabs } from '@mui/material';

import '../../../assets/css/sidebar_general.css'
import { decode } from 'base-64';
import _route from './_route';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import Content from './Content';


const TabScrollButton = forwardRef((props, ref) => {
        const { direction, ...other } = props;
    
        return (
            <ButtonBase
                component="div"
                ref={ref}
                style={{ 
                    opacity: other.disabled ? 0 : 1 ,
                    marginBottom: 10,
                }}
                {...other}
            >
                {direction === "left" ? 
                    (
                        <span className=" me-3 p-1 rounded-circle" style={{backgroundColor: '#10ad92', color: '#ffffff'}}>
                            <NavigateBefore fontSize="small" />
                        </span>
                    ) : 
                    (
                        <span className=" ms-3 p-1 rounded-circle" style={{backgroundColor: '#10ad92', color: '#ffffff'}}>
                            <NavigateNext fontSize="small" />
                        </span>
                    )
                }
            </ButtonBase>
        );
  });

const General = () => {
    const [tab, setTab] = useState('login');
    const type = localStorage.getItem('type');
    const [route, setRoute] = useState([])

    const getVideo = () => {
        if (type && type !== null && type !== 'null' && type !== '') {
            const data = _route.filter(obj => obj.code === decode(JSON.parse(type)));
            setRoute(data[0]['route'])
        }
    }

    useEffect(() => {
        getVideo()
    }, [])

    const handleChangeTab = (event, newValue) => {
        setTab(newValue)
    };

    return (
        <Layouts>
            <TabContext value={tab}>
                <div className="px-6 d-flex justify-content-center">
                    <div style={{maxWidth: '100%'}}>
                        <Tabs 
                            value={tab}
                            onChange={handleChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            ScrollButtonComponent={TabScrollButton}
                            ScrollB
                        >
                            {
                                route.map((data, index) => (
                                    <Tab 
                                        key={index} 
                                        label={data.label} 
                                        value={data.category} 
                                        sx={{
                                            mr: 3,
                                            padding: '.5rem 1rem',
                                            backgroundColor: '#ffffff',
                                            minHeight: 0,
                                            color: '#10ad92',
                                            border: '1px solid #10ad92',
                                            borderRadius: 2,
                                            ':hover' : {
                                                backgroundColor: '#10ad92',
                                                color: '#ffffff'
                                            },
                                            '&.Mui-selected' : {
                                                backgroundColor: '#10ad92',
                                                color: '#ffffff'
                                            },
                                        }}
                                    />
                                ))
                            }
                        </Tabs>
                    </div>
                </div>
                <div className="px-4">
                        {
                            route.map((data, index) => (
                                <TabPanel key={index} value={data.category}>
                                    <Content list={data.content} />
                                </TabPanel>
                            ))
                        }
                </div>
            </TabContext>
        </Layouts>
    )
}

export default General
