import React from 'react'
import Article from '../../views/article/Article'
import ArticleDetail from '../../views/article/ArticleDetail'

function ArticleController({ view }) {
    if (view === 'detail') {
        return (
            <ArticleDetail />
        )
    } else {
        return (
            <Article />
        )
    }
}

export default ArticleController
