import React, { useEffect, useState } from 'react'
import KpiIndicator from '../KpiIndicator'
import { Loading, codeDate, yearNow } from '../../../../utils'
import { useDispatch } from 'react-redux'
import { Autocomplete, Button, TextField } from '@mui/material'
import { getBidang } from '../../../../model/bidang/bidang_m'
import { storeKpiIndicator } from '../../../../model/kpi/kpi_indicator_m'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

const KpiIndicatorForm = () => {
    const dispatch = useDispatch()
    const { username } = useParams()

    const [bidang, setBidang] = useState([])
    const getDataBidang = async() => {
        try {
            const response = await dispatch(getBidang(1)).unwrap().catch((err) => {})
            setBidang(response.data.map((data) => ({code: data.code, label: data.name})))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataBidang()
    }, [])

    const [input, setInput] = useState({
        code: `IDT${codeDate}`, tahun: yearNow, code_bidang: '', bidang: '', bisnis: '', non_bisnis: ''
    })
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)

    const handleChangeBobotBisnis = (e) => {
        const bobot = e.target.value;
        if (! Number(bobot)) {
           setInput({...input, bisnis: '', non_bisnis: ''}) 
        } else {
            if (bobot > 100) {
                setInput({...input, bisnis: 100, non_bisnis: 0}) 
            } else {
                const sisa = 100 - bobot
                setInput({...input, bisnis: parseInt(bobot), non_bisnis: sisa}) 
            }
        }
    }

    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const data = {...input}
            const response = await dispatch(storeKpiIndicator(data)).unwrap().catch((err) => {})
            if (response.status === 201) {
                toast.success('KPI Indikator Berhasil Ditambahkan')
                setTimeout(() => {
                    return window.location.href = `/${username}/kpi/indicator/data`
                }, 1200);
            }

            if (response.status === 400) {
                toast.error('Periksa Kembali Data Yang Anda Inputkan')
                setError(response.message)
                setLoading(false)
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }
    return (
        <KpiIndicator>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">INPUT KPI INDIKATOR</h3>
                            <span>Form Input KPI Indikator</span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Kode KPI Indikator</label>
                                    <input type="text" value={input.code} disabled className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Tahun</label>
                                    <input type="text" className="form-control" value={input.tahun} disabled />
                                </div>
                                <div>
                                    <label className="form-label">Master Bidang <span className="text-danger">*</span></label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={bidang}
                                        renderInput={(params) => <TextField {...params} error={ error.code_bidang ? true : false } placeholder="Pilih Master Bidang" required />}
                                        onChange={(_, event) => setInput({...input, code_bidang: event.code, bidang: event.label})}
                                    />
                                    <small className="text-danger">{ error.code_bidang ? error.code_bidang : '' }</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Bobot Bisnis ( % ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Bobot Bisnis"
                                        value={input.bisnis}
                                        onChange={handleChangeBobotBisnis}
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Bobot Non Bisnis ( % ) <span className="text-danger">*</span></label>
                                   <input type="text" value={input.non_bisnis} disabled placeholder="Otomatis" className="form-control" />
                                </div>
                                <div className="d-flex justify-content-end mb-1">
                                    <Button variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </KpiIndicator>
    )
}

export default KpiIndicatorForm
