import { Article, GridView, SaveAs } from "@mui/icons-material"

const _kpm = [
    {
        name: 'Dashboard',
        icon: GridView,
        to: 'dashboard',
        active: 'dashboard'
    },
    {
        name: 'RKAP',
        icon: SaveAs,
        to: 'rkap/data',
        active: 'rkap'
    },
    {
        name: 'Catatan Dewas',
        icon: Article,                                                                                                                                                                                                                                                                                                                      
        to: 'article',
        active: 'article'
    },
]

export default _kpm