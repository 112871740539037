import React, { useEffect, useState } from 'react'
import KpiVariable from '../KpiVariable'
import { Loading, yearNow } from '../../../../utils'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getAllKpiIndicator } from '../../../../model/kpi/kpi_indicator_m'
import toast from 'react-hot-toast'
import { storeKpiVariable } from '../../../../model/kpi/kpi_variable_m'
import { useParams } from 'react-router-dom'

const status = [
    { code: 'bisnis', label: 'Bisnis' },
    { code: 'non', label: 'Non Bisnis' }
]

const status_kas = [
    { code: 'masuk', label: 'Uang Masuk' },
    { code: 'keluar', label: 'Uang Keluar' }
]

const KPiVariableForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams()

    const [indicator, setIndicator] = useState([])
    const getDataIndicator = async() => {
        try {
            const response = await dispatch(getAllKpiIndicator()).unwrap().catch((err) => {})
            setIndicator(response.data.map((data) => ({
                code: data.code, code_bidang: data.code_bidang, label: data.bidang, bisnis: data.bisnis,
                non_bisnis: data.non_bisnis
            })))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataIndicator()
    }, [])

    const date = new Date();
    const codeDate = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const [input, setInput] = useState({
        tahun: yearNow, code_indicator: '', code_bidang: '', bidang: '', status: '', status_label: '',
        bisnis: '', non_bisnis: '', bobot: ''
    })
    const [add, setAdd] = useState({
        code: `VR${codeDate}`, name: '', bobot: '', status_kas: '', status_kas_label: ''
    })
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])

    const handleChangeIndicator = (_, event) => {
        if (event) {
            setInput({
                ...input,
                code_indicator: event.code, code_bidang: event.code_bidang, bidang: event.label,
                bisnis: event.bisnis, non_bisnis: event.non_bisnis,status: '', status_label: '', bobot: ''
            })
            setList([])
        }
    }

    const handleChangeStatus = (_, event) => {
        if (event) {
            if (event.code === 'bisnis') {
                setInput({...input, status: event.code, bobot: input.bisnis})
            } else {
                setInput({...input, status: event.code, status_label: event.label, bobot: input.non_bisnis})
                setAdd({...add, name: '', bobot: ''})
                setList([])
            }
        }
    }

    // list
    const total_bobot = list.reduce((i, j) => i + j.bobot, 0);

    const handleChangeBobot = (e) => {
        const bobot = parseInt(e.target.value);
        if (! Number(bobot)) {
            setAdd({...add, bobot: ''})
        } else {
            if (bobot + total_bobot > input.bobot) {
                toast.error(`Bobot Yang Anda Masukkan Melebihi dari ${input.bobot} %`)
                setAdd({...add, bobot:''})
            } else {
                setAdd({...add, bobot: bobot})
            }
        }
    }

    const onAddData = () => {
        if (add.name === '' || add.bobot === '' || add.status_kas === '') {
            toast.error('Variabel, Bobot, dan Status Kas Wajib Diisi')
        } else {
            setList([...list, {
                code: add.code,
                tahun: input.tahun,
                code_bidang: input.code_bidang,
                code_kpi_indicator: input.code_indicator,
                bidang: input.bidang,
                name: add.name,
                status: input.status,
                bobot: add.bobot,
                status_kas: add.status_kas,
                bobot_indicator: input.bobot
            }])
            setAdd({code: `VR${codeDate}`, name: '', bobot: '', status_kas: ''})
        }
    }

    const onDeleteData = (index) => {
        const array = Array.from(list)
        array.splice(index, 1)
        setList(array)
    }

    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const data = {list: {...list}}
            const response = await dispatch(storeKpiVariable(data)).unwrap().catch((err) => {})
            if (response.status === 201) {
                toast.success('KPI Variabel Berhasil Disimpan')
                setTimeout(() => {
                    return window.location.href = `/${username}/kpi/variable/data`
                }, 1200);
            }

            if (response.status === 400) {
                toast.error(`Periksa Data KPI Variabel Yang Anda Inputkan | ${response.message.code}`)
                setLoading(false)
                setError(response.message)
                setList([])
                setInput({
                    ...input, code_indicator: '', code_bidang: '', bidang: '', status: '', status_label: '',
                    bisnis: '', non_bisnis: '', bobot: ''
                })
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }

    return (
        <KpiVariable>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">INPUT KPI Variabel</h3>
                            <span>Form Input KPI Variabel</span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Tahun</label>
                                    <input type="text" value={input.tahun} disabled className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Pilih Bidang</label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={indicator}
                                        value={input.bidang}
                                        renderInput={(params) => <TextField {...params} placeholder="Pilih Bidang" />}
                                        onChange={handleChangeIndicator}
                                    />
                                    <small className="text-danger">{ error.code ? error.code : '' }</small>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Status</label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={status}
                                        value={input.status_label}
                                        renderInput={(params) => <TextField {...params} placeholder="Status" />}
                                        onChange={handleChangeStatus}
                                        disabled={ input.code_indicator ? false : true }
                                    />
                                </div>
                                <div>
                                    <label className="form-label">Bobot ( % )</label>
                                    <input type="text" placeholder="Otomatis" disabled className="form-control" value={input.bobot} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-end" style={{marginRight: 0}}>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Kode Variabel</label>
                                        <input type="text" value={add.code} disabled className="form-control" />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label className="form-label">Nama Variabel <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Nama Variabel"
                                            value={add.name}
                                            onChange={(e) => setAdd({...add, name: e.target.value})}
                                            disabled={ input.status ? false : true }
                                        />
                                    </div>
                                    <div className="col-md-2 mb-3">
                                        <label className="form-label">Bobot ( % ) <span className="text-danger">*</span></label>
                                        <TextField 
                                            size="small"
                                            fullWidth
                                            placeholder="Bobot"
                                            value={add.bobot}
                                            onChange={handleChangeBobot}
                                            disabled={ input.status ? false : true }
                                        />
                                    </div>
                                    <div className="col-md-2 mb-3">
                                        <label className="form-label">Status Kas</label>
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            disableClearable
                                            options={status_kas}
                                            value={add.status_kas_label}
                                            renderInput={(params) => <TextField {...params} placeholder="Status Kas" />}
                                            onChange={(_, event) => setAdd({...add, status_kas: event.code})}
                                        />
                                    </div>
                                    <div className="col-md-1 mb-3">
                                        <Button onClick={onAddData} disabled={ input.status ? false : true } sx={{padding: '7.5px 14px'}} fullWidth variant="contained" color="primary">
                                            Add
                                        </Button>
                                    </div>
                                </div>

                                <div className="table-responsive mb-4">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control fw-bold">No</span>
                                                </th>
                                                <th style={{width: '20%'}}>
                                                    <span className="form-control fw-bold">Kode Variabel</span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">Variabel</span>
                                                </th>
                                                <th className="text-center" style={{width: '10%'}}>
                                                    <span className="form-control fw-bold">Bobot</span>
                                                </th>
                                                <th className="text-center" style={{width: '10%'}}>
                                                    <span className="form-control fw-bold">Status Kas</span>
                                                </th>
                                                <th className="table-border-end text-center" style={{width: '10%'}}>
                                                    <span className="form-control fw-bold">Action</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                list.length > 0 ?
                                                list.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">
                                                            { index + 1 > 9 ? index + 1 : `0${index + 1}` }
                                                        </td>
                                                        <td>{ data.code }</td>
                                                        <td>{ data.name }</td>
                                                        <td className="text-center">{ data.bobot } %</td>
                                                        <td className="text-center">
                                                            {
                                                                data.status_kas === 'masuk' ? 
                                                                <span className="badge bg-success">Uang Masuk</span> : 
                                                                <span className="badge bg-pink">Uang Keluar</span>
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            <Button size="small" variant="contained" color="error" onClick={() => onDeleteData(index)}>
                                                                Delete
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )) : 
                                                <tr>
                                                    <th colSpan={5} className="text-center">DATA MASIH KOSONG</th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <Button disabled={ list.length > 0 && total_bobot === parseInt(input.bobot) ? false : true } variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </KpiVariable>
    )
}

export default KPiVariableForm
