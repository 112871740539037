import React, { useEffect, useState } from 'react'
import Layouts from '../layouts/Layouts'
import { useNavigate, useParams } from 'react-router-dom'
import { AccessTime, AccountCircle, ReplyAll, RuleFolder } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { detailNote } from '../../model/note/note_m'
import toast, { Toaster } from 'react-hot-toast'
import { Loading } from '../../utils'
import { noimage } from '../images'
import parse from 'html-react-parser';
import Moment from 'react-moment'

const ArticleDetail = () => {
    const dispatch = useDispatch();
    const { username, code } = useParams()
    const navigate = useNavigate()
    const url_back = `/${username}/article`

    const [note, setNote] = useState([])
    const [loading, setLoading] = useState(false)

    const getData = async() => {
        try {
            const response = await dispatch(detailNote(code)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setNote(response.data)
            }

            if (response.status === 404) {
                setLoading(true)
                toast.error('Data Yang Anda Cari Tidak Ditemukan')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <Layouts>
            <Toaster position="top-center" />
            { loading ? <Loading /> : '' }
            <div className="d-flex justify-content-between">
                <div>
                    <h2>DETAIL CATATAN DEWAN PENGAWAS</h2>
                </div>
                <div>
                    <span className="text-orange" style={{cursor: 'pointer'}} onClick={() => navigate(-1)}>
                        <ReplyAll />
                    </span>
                </div>
            </div>
            <div className="mt-3" style={{padding: '0 10rem'}}>
                <div>
                    <div className="mb-3 text-center">
                        <img src={ note.image ? note.image : noimage } alt="" style={{width: '100%', height: 350, borderRadius: 4, boxShadow: '#00000080 1px 1px 8px'}} />
                    </div>
                    <div className="mb-3 d-flex justify-content-center">
                        <div>
                            <div className="text-center">
                                <h2>{ note.title }</h2>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="me-3">
                                    <RuleFolder /> { note.bidang }
                                </div>
                                <div className="me-3">
                                    <AccountCircle /> { note.author }
                                </div>
                                <div>
                                    <AccessTime /> <Moment format="dddd, DD MMMM YYYY">{ note.date }</Moment>
                                </div>
                            </div>
                        </div>
                    </div>
                    { parse(`${note.content}`) }
                </div>
            </div>
        </Layouts>
    )
}

export default ArticleDetail
