const date = new Date();
export const codeDate = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
export const codeTime = `${date.getHours()} : ${date.getMinutes()} : ${date.getSeconds()}`
export const dateNow = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
export const yearNow = date.getFullYear()

// periode per 3 bulan
const month = date.getMonth() + 1
let periode
if (month === 1) {
    periode = 1
} else if (month === 4) {
    periode = 2
} else if (month === 7) {
    periode = 3
} else {
    periode = 4
}

export const periodeData = periode;

