import React from 'react'
import Kpi from '../Kpi'
import { Link, useLocation, useParams } from 'react-router-dom';
import { PlaylistAddCheckCircle } from '@mui/icons-material';
import { decode } from 'base-64';

const KpiVariable = ({ children }) => {
    const { username } = useParams();
    const location = useLocation();
    const path = location.pathname;
    const split = path.split('/');

    const type = localStorage.getItem('type');

    return (
        <Kpi>
            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="sub-menu">
                            <ul>
                                {
                                    decode(JSON.parse(type)) === 'direksi' || decode(JSON.parse(type)) === 'admin' ? 
                                    <li>
                                        <Link to={`/${username}/kpi/variable/form`} className={` sub-menu-item ${split[4] === 'form'? 'active' : ''} `} >
                                            <div className="me-2">
                                                <PlaylistAddCheckCircle />
                                            </div>
                                            <span>Input KPI Variabel</span>
                                        </Link>
                                    </li> : ''
                                }
                                <li>
                                    <Link to={`/${username}/kpi/variable/data`} className={` sub-menu-item ${split[4] === 'data'? 'active' : ''} `} >
                                        <div className="me-2">
                                            <PlaylistAddCheckCircle />
                                        </div>
                                        <span>Data KPI Variabel</span>
                                    </Link>
                                </li>
                                {
                                    decode(JSON.parse(type)) === 'direksi' || decode(JSON.parse(type)) === 'admin' ? 
                                    <li>
                                        <Link to={`/${username}/kpi/variable/edit`} className={` sub-menu-item ${split[4] === 'edit'? 'active' : ''} `} >
                                            <div className="me-2">
                                                <PlaylistAddCheckCircle />
                                            </div>
                                            <span>Edit Bobot</span>
                                        </Link>
                                    </li> : ''
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                
            { children }
        </Kpi>
    )
}

export default KpiVariable
