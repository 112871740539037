import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import LoginController from '../controller/login/LoginController'
import access_routes from './access_routes'
import { decode } from 'base-64'

import '../assets/css/tabler.css'
import '../assets/css/style.css'
import ScreenController from '../controller/screen/ScreenController'
import AccountSettingController from '../controller/account/AccountSettingController'
import GeneralController from '../controller/general/GeneralController'
import DashboardController from '../controller/dashboard/DashboardController'

function Web() {
    const type = localStorage.getItem('type');
    const [route, setRoute] = useState([])

    const getRoute = async() => {
        if (type && type !== null && type !== 'null' && type !== '') {
            const data = access_routes.filter(obj => obj.type === decode(JSON.parse(type)));
            setRoute(data[0]['route'])
        }
    }

    useEffect(() => {
        getRoute()
    }, [])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="" element={ <LoginController /> } />
                <Route path="/:username/screen-welcome" element={ <ScreenController /> } />
                <Route path="/:username/my-account" element={ <AccountSettingController /> } />
                <Route path="/:username/general-information" element={ <GeneralController /> } />
                <Route path="/:username/dashboard" element={ <DashboardController /> } />
                {
                    route.length > 0 ? 
                    route.map((route, index) => (
                        <Route 
                            key={index}
                            path={ `/:username/${route.path}` }
                            element={ <route.element view={ route.view } /> }
                        />
                    )) : ''
                }
            </Routes>
        </BrowserRouter>
    )
}

export default Web
