import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import Layouts from '../layouts/Layouts';
import { PlaylistAddCheckCircle, PlaylistAddCircle } from '@mui/icons-material';
import { decode } from 'base-64';

const Rkap = ({ children }) => {
    const { username } = useParams();
    const location = useLocation();
    const path = location.pathname;
    const split = path.split('/');

    const type = localStorage.getItem('type');

    return (
        <Layouts>
            <div>
                <h1>RENCANA KERJA DAN ANGGARAN PERUSAHAAN ( RKAP )</h1>
            </div>

            <div className="mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="sub-menu">
                            <ul>
                                {
                                    decode(JSON.parse(type)) === 'admin' || decode(JSON.parse(type)) === 'direksi' ? 
                                    <li>
                                        <Link to={`/${username}/rkap/form`} className={` sub-menu-item ${split[3] === 'form'? 'active' : ''} `}>
                                            <div className="me-2">
                                                <PlaylistAddCircle />
                                            </div>
                                            <span>Input RKAP</span>
                                        </Link>
                                    </li> : ''
                                }
                                <li>
                                    <Link to={`/${username}/rkap/data`} className={` sub-menu-item ${split[3] === 'data'? 'active' : ''} `} >
                                        <div className="me-2">
                                            <PlaylistAddCheckCircle />
                                        </div>
                                        <span>Data RKAP</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                
            { children }
        </Layouts>
    )
}

export default Rkap
