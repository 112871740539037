import { AccountTree, Article, GridView, SaveAs } from "@mui/icons-material";

const _spi = [
    {
        name: 'Dashboard',
        icon: GridView,
        to: 'dashboard',
        active: 'dashboard'
    },
    {
        name: 'KPI',
        icon: AccountTree,
        to: 'kpi/indicator/data',
        active: 'kpi'
    },
    {
        name: 'RKAP',
        icon: SaveAs,
        to: 'rkap/data',
        active: 'rkap'
    },
    {
        name: 'Catatan Dewas',
        icon: Article,                                                                                                                                                                                                                                                                                                                      
        to: 'article',
        active: 'article'
    },
]
export default _spi;