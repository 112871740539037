import React from 'react'
import Login from '../../views/login/Login'

function LoginController() {
    return (
        <Login />
    )
}

export default LoginController
