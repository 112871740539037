import axios from "axios";

const developer = 'http://127.0.0.1:8000/api'
const production = 'https://api-ldrshp.perumdajepara.co.id/api'

const http = axios.create({
    baseURL: production,
    withCredentials: true
})

http.defaults.headers.common['token'] = 'ZhbHVlIjoiUEVZM0p0SmN5QXdWbnB6Y3pqNVZRdz09IiwibWFjIjoiMzhjYjg2MmVkN2JjNDcxNzZjMjljYjdkNGMzNDUwOTY3M2NiN2ZiNz'
http.defaults.headers.common['Content-Type'] = 'multipart/form-data'

export default http;