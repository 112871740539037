import React, { useState } from 'react'
import User from '../User'
import { Autocomplete, Button, TextField } from '@mui/material'
import { Loading, codeDate, imageResizer } from '../../../utils'
import { useDispatch } from 'react-redux'
import { storeProfile } from '../../../model/profile/profile_m'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

const access = [
    { code: 'dewas', label: 'DEWAS' },
    { code: 'direksi', label: 'DIREKSI' },
    { code: 'spi', label: 'SPI' },
    { code: 'kpm', label: 'KPM' }
]

const UserForm = () => {
    const dispatch = useDispatch();
    const { username } = useParams()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [input, setInput] = useState({
        code: `USR${codeDate}`, name: '', posisi: '', jabatan: '', username: '', password: '', photo: '', type: '',
        type_label: ''
    })

    const handleChangeFile = async(e) => {
        const file = e.target.files[0]
        const resize = await imageResizer(file, 240, 240);
        setInput({...input, photo: resize})
    }

    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const data = {...input}
            const response = await dispatch(storeProfile(data)).unwrap().catch((err) => {})
            if (response.status === 201) {
                toast.success('Data User Berhasil Ditambahkan')
                setTimeout(() => {
                    return window.location.href = `/${username}/user/data`
                }, 1200);
            }

            if (response.status === 400) {
                toast.error('Periksa Kembali Data Yang Anda Inputkan')
                setError(response.message)
                setLoading(false)
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }

    return (
        <User>
            { loading ? <Loading /> : '' }
            <form onSubmit={onSubmit} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Kode User</label>
                                    <input type="text" disabled value={input.code} className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Nama User <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Nama User"
                                        required
                                        value={input.name}
                                        onChange={(e) => setInput({...input, name: e.target.value})}
                                    />
                                </div>
                                <div>
                                    <label className="form-label">Posisi <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Posisi"
                                        required
                                        value={input.posisi}
                                        onChange={(e) => setInput({...input, posisi: e.target.value})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Jabatan <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        placeholder="Tulis Jabatan"
                                        fullWidth
                                        required
                                        value={input.jabatan}
                                        onChange={(e) => setInput({...input, jabatan: e.target.value})}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Akses Sebagai <span className="text-danger">*</span></label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={access}
                                        value={input.type_label}
                                        renderInput={(params) => <TextField {...params} placeholder="Pilih Akses" required />}
                                        onChange={(_, event) => setInput({...input, type: event.code, type_label: event.label})}
                                    />
                                </div>
                                <div>
                                    <label className="form-label">Foto ( 800 x 800 px )</label>
                                    <input type="file" accept="image/png, image/jpg, image/jpeg" className="form-control" onChange={handleChangeFile} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Username <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Username"
                                        required
                                        value={input.username}
                                        onChange={(e) => setInput({...input, username: e.target.value.toLocaleLowerCase().trim()})}
                                    />
                                    <small className="text-danger">{ error.username ? error.username : '' }</small>
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Password <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Password"
                                        required
                                        value={input.password}
                                        onChange={(e) => setInput({...input, password: e.target.value.toLocaleLowerCase().trim()})}
                                    />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button sx={{padding: '9px 14px'}} variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </User>
    )
}

export default UserForm
