import React, { useState } from 'react'
import Bidang from '../Bidang'
import { Button } from '@mui/material'
import { Loading, codeDate, dateNow } from '../../../utils'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { storeBidang } from '../../../model/bidang/bidang_m'
import toast from 'react-hot-toast'

const BidangForm = () => {
    const { username } = useParams()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [input, setInput] = useState({
        code: `DB${codeDate}`, date: dateNow, name: '', desc: ''
    })
    
    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const data = {...input}
            const response = await dispatch(storeBidang(data)).unwrap().catch((err) => {})
            if (response.status === 201) {
                toast.success('Master Data Bidang Berhasil Ditambahkan')
                setTimeout(() => {
                    return window.location.href = `/${username}/bidang/data`
                }, 1200);
            }

            if (response.status === 400) {
                toast.error('Periksa Kembali Data Input Anda')
                setLoading(false)
                setError(response.message)
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }

    return (
        <Bidang>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3>INPUT MASTER BIDANG</h3>
                            <span>Form Tambah Master Bidang</span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Kode Bidang</label>
                                    <input type="text" value={input.code} disabled className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Tanggal</label>
                                    <span className="form-control" style={{backgroundColor: '#e1e1e1'}}>
                                        <Moment format="dddd, DD MMMM YYYY">{ input.date }</Moment>
                                    </span>
                                </div>
                                <div>
                                    <label className="form-label">Nama Bidang <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Tulis Nama Bidang" value={input.name} onChange={(e) => setInput({...input, name: e.target.value})} required />
                                    <small className="text-danger">{ error.name ? error.name : '' }</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Keterangan</label>
                                    <textarea className="form-control" style={{height: 136}} placeholder="Tulis Keterangan" value={input.desc} onChange={(e) => setInput({...input, desc: e.target.value})}></textarea>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Bidang>
    )
}

export default BidangForm
