import React, { useEffect, useRef, useState } from 'react'
import Note from '../Note'
import { useParams } from 'react-router-dom'
import { ReplyAll } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { detailNote, updateNote } from '../../../model/note/note_m'
import toast from 'react-hot-toast'
import { Loading, imageResizer } from '../../../utils'
import { Button, TextField } from '@mui/material'
import Moment from 'react-moment'
import { Editor } from '@tinymce/tinymce-react'

const NoteDataDetail = () => {
    const dispatch = useDispatch()
    const { username, code } = useParams()
    const url_back = `/${username}/note/data`

    const editorRef = useRef(null)

    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState([])
    const [input, setInput] = useState({
        title: '', short_desc: '', image: ''
    })
    const [error, setError] = useState([])

    const getData = async() => {
        try {
            const response = await dispatch(detailNote(code)).unwrap().catch((err) => {})
            if (response.status === 200) {
                const result = response.data;
                setNote(response.data)
                setInput({...input, title: result.title, content: result.content, short_desc: result.short_desc ? result.short_desc : ''})
            }

            if (response.status === 404) {
                toast.error('Data Yang Anda Cari Tidak Ditemukan')
                setLoading(true)
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const handleChangeImage = async(e) => {
        if (e.target.value !== '') {
            const file = e.target.files[0]
            const resize = await imageResizer(file, 480, 480);
            setInput({...input, image: resize})
        }
    }

    const onUpdate = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (editorRef.current) {
                input.content = editorRef.current.getContent()
            } else {
                input.content = ''
            }
            const data = {slug: code, ...input}
            const response = await dispatch(updateNote(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Catatan Dewas Berhasil Diperbaharui')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }


            if (response.status === 400) {
                toast.error('Periksa Kembali Data Anda')
                setError(response.message)
                setLoading(false)
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }

    return (
        <Note>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">{ note.title }</h3>
                            <span>Detail Catatan Dewas</span>
                        </div>
                        <div>
                            <span className="text-orange" style={{cursor: 'pointer'}} onClick={() => window.location.href = url_back}>
                                <ReplyAll />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={onUpdate} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-9 mb-3">
                        <div className="card" style={{height: 510}}>
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Judul Catatan <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Judul Catatan"
                                        value={input.title}
                                        required
                                        onChange={(e) => setInput({...input, title:e.target.value})}
                                    />
                                    <small className="text-danger">{ error.title ? error.title : '' }</small>
                                </div>
                                <div>
                                    <Editor 
                                        apiKey="n439af4cclgxfvbhrov0gwvas7n41i75iukgza52nizryl7m"
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={input.content}
                                        init={{
                                            menubar: false,
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                                                'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'wordcount'
                                            ],
                                            toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat',
                                            setup: (editor) => {
                                                editor.on('init', () => {
                                                    editor.getContainer().style.border = '1px solid #00000042';
                                                    editor.getContainer().style.borderRadius = '4px';
                                                })
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Bidang</label>
                                    <span className="form-control" style={{backgroundColor: 'rgba(0, 0, 0, 0.23)'}}>{ note.bidang }</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Dewas</label>
                                    <span className="form-control" style={{backgroundColor: 'rgba(0, 0, 0, 0.23)'}}>{ note.author }</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Tanggal</label>
                                    <span className="form-control" style={{backgroundColor: 'rgba(0, 0, 0, 0.23)'}}>
                                        <Moment format="dddd, DD MMMM YYYY">{ note.date }</Moment>
                                    </span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Deskripsi Singkat</label>
                                    <textarea placeholder="Tulis Deskripsi Singkat" defaultValue={input.short_desc} onChange={(e) => setInput({...input, short_desc: e.target.value})} className="form-control"></textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Gambar &nbsp; &nbsp;
                                        {
                                            note.image ? <a href={ note.image } target="_blank">Lihat Gambar</a> : ''
                                        }
                                    </label>
                                    <input type="file" accept="image/png, image/jpg, image/jpeg" className="form-control" onChange={handleChangeImage} />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button variant="contained" color="success" type="submit">
                                        Update Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Note>
    )
}

export default NoteDataDetail
