import React from 'react'
import NoteForm from '../../views/note/form/NoteForm'
import NoteData from '../../views/note/data/NoteData'
import NoteDataDetail from '../../views/note/data/NoteDataDetail'

function NoteController({ view }) {
    if (view === 'form') {
        return (
            <NoteForm />
        )
    } else if (view === 'detail') {
        return (
            <NoteDataDetail />
        )
    } else {
        return (
            <NoteData />
        )
    }
}

export default NoteController
