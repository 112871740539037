import React from 'react'

const Grade = ({ percent, indicator }) => {
    let grade;
    let status_grade;
    let color;
    if (percent >= parseFloat(indicator.grade_a)) {
        grade = 'A'
        status_grade = 'Sangat Baik'
        color = '#1abf01'
    } else if (percent >= parseFloat(indicator.grade_b) && percent < parseFloat(indicator.grade_a)) {
        grade = 'B'
        status_grade = 'Baik'
        color = '#8dd200'
    } else if (percent >= parseFloat(indicator.grade_c) && percent < parseFloat(indicator.grade_b)) {
        grade = 'C'
        status_grade = 'Kurang Baik'
        color = '#fece00'
    } else if (percent >= parseFloat(indicator.grade_d) && percent < parseFloat(indicator.grade_c)) {
        grade = 'D'
        status_grade = 'Buruk'
        color = '#fc9a00'
    } else {
        grade = 'E'
        status_grade = 'Sangat Buruk'
        color = '#ff3201'
    }

    return (
        <div className="text-center" style={{ lineHeight: 0.95 }}>
            <span style={{ fontSize: 150, fontWeight: 'bold', color: color }}>
                { grade }
            </span> <br />
            <span className="fw-bold" style={{ fontSize: 18 }}>KPI GRADE</span> <br />
            <small className="text-uppercase" style={{ fontSize: 10 }}>
                { status_grade }
            </small>
        </div>
    )
}

export default Grade
