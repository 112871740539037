import React, { useEffect, useState } from 'react'
import Bidang from '../Bidang'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getDetailBidang, updateBidang } from '../../../model/bidang/bidang_m'
import { Loading } from '../../../utils'
import toast from 'react-hot-toast'
import { ReplyAll } from '@mui/icons-material'
import Moment from 'react-moment'
import { Button } from '@mui/material'

const BidangDataDetail = () => {
    const dispatch = useDispatch()
    const { username, code } = useParams()
    const url_back = `/${username}/bidang/data`

    const [loading, setLoading] = useState(false)
    const [bidang, setBidang] = useState([])

    const [input, setInput] = useState({
        code: '', date: '', name: '', desc: ''
    })
    const [error, setError] = useState([])
    
    const getData = async(code) => {
        try {
            const response = await dispatch(getDetailBidang(code)).unwrap().catch((err) => {})
            if (response.status === 200) {
                const result = response.data;
                setBidang(result)
                setInput({code: result.code, date: result.date, name: result.name, desc: result.desc ? result.desc : ''})
            }

            if (response.status === 404) {
                setLoading(true)
                toast.error('Data Yang Anda Cari Tidak Ditemukan')
                setTimeout(() => {
                    return window.location.href = url_back
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(code)
    }, [])

    const onUpdate = async(e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const data = {...input}
            const response = await dispatch(updateBidang(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Master Bidang Berhasil Diperbaharui')
                setTimeout(() => {
                    return window.location.reload()
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Bidang>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">DETAIL { bidang.name }</h3>
                            <span>Detail Data Master Bidang</span>
                        </div>
                        <div>
                            <span className="text-orange" style={{cursor: 'pointer'}} onClick={() => window.location.href = url_back}>
                                <ReplyAll />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onUpdate} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Kode Bidang</label>
                                    <input type="text" value={input.code} disabled className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Tanggal</label>
                                    <span className="form-control" style={{backgroundColor: '#e1e1e1'}}>
                                        <Moment format="dddd, DD MMMM YYYY">{ input.date }</Moment>
                                    </span>
                                </div>
                                <div>
                                    <label className="form-label">Nama Bidang <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Tulis Nama Bidang" required value={input.name} onChange={(e) => setInput({...input, name: e.target.value})} />
                                    <small className="text-danger">{ error.name ? error.name : '' }</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Keterangan</label>
                                    <textarea className="form-control" style={{height: 136}} placeholder="Tulis Keterangan" value={input.desc} onChange={(e) => setInput({...input, desc: e.target.value})}></textarea>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button variant="contained" color="success" type="submit">
                                        Update Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Bidang>
    )
}

export default BidangDataDetail
