import { account, article, dashboard, login, rkap } from '../content/kpm/kpm_content'

const _kpm = [
    { category: 'login', label: 'Login', content: login },
    { category: 'account', label: 'My Account', content: account },
    { category: 'dashboard', label: 'Dashboard', content: dashboard },
    { category: 'rkap', label: 'RKAP', content: rkap },
    { category: 'article', label: 'Catatan Dewas', content: article },
]

export default _kpm;