import React from 'react'
import KpiScoreForm from '../../views/kpi/score/form/KpiScoreForm'
import KpiScoreData from '../../views/kpi/score/data/KpiScoreData'
import KpiScoreDataDetail from '../../views/kpi/score/data/KpiScoreDataDetail'

function KpiScoreController({ view }) {
    if (view === 'form') {
        return (
            <KpiScoreForm />
        )
    } else if (view === 'detail') {
        return (
            <KpiScoreDataDetail />
        )
    } else {
        return (
            <KpiScoreData />
        )
    }
}

export default KpiScoreController
