import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../http";

const initialState = {}

export const loginUser = createAsyncThunk('login/loginUser', async(data) => {
    try {
        const response = await http.post('/user/login', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const logoutnUser = createAsyncThunk('login/logoutnUser', async(data) => {
    try {
        const response = await http.post('/user/logout', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

const login_m = createSlice({
    name: 'login',
    initialState
})

export default login_m.reducer;