import React from 'react'
import KpiIndicatorForm from '../../views/kpi/indicator/form/KpiIndicatorForm'
import KpiIndicatorData from '../../views/kpi/indicator/data/KpiIndicatorData'
import KpiIndicatorDataDetail from '../../views/kpi/indicator/data/KpiIndicatorDataDetail'

function KpiIndicatorController({ view }) {
    if (view === 'form') {
        return (
            <KpiIndicatorForm />
        )
    } else if (view === 'detail') {
        return (
            <KpiIndicatorDataDetail />
        )
    } else {
        return (
            <KpiIndicatorData />
        )
    }
}

export default KpiIndicatorController
