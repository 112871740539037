import React from 'react'

const Content = ({ list }) => {
    return (
        <div>
            <div className="row gx-4">
                {
                    list.map((data, index) => (
                        <div className="col-md-3 mb-3" key={index}>
                            <div className="card">
                                {data.iframe}
                                <div className="card-body">
                                    <div>
                                        <h3 className="text-uppercase">{ data.title }</h3>
                                    </div>
                                    <div>
                                        { data.short_desc }
                                    </div>
                                </div>
                                <div>
                                    <a href={ data.link } target="_blank" className="btn w-100" style={{backgroundColor: '#10ad92', color: '#ffffff'}}>
                                        Tonton Video
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Content
