import React, { useEffect, useState } from 'react'
import KpiVariable from '../KpiVariable'
import { useDispatch } from 'react-redux'
import { getKpiVariable } from '../../../../model/kpi/kpi_variable_m'
import { PageData } from '../../../../utils'

const KpiVariableData = () => {
    const dispatch = useDispatch()

    const [variable, setVariable] = useState([])
    const [pagination, setPagination] = useState([])

    const getData = async(page) => {
        try {
            const response = await dispatch(getKpiVariable(page)).unwrap().catch((err) => {})
            setVariable(response.data)
            setPagination(response.pagination)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1)
    }, [])

    const handlePagination = (_, page) => {
        return getData(page)
    }
    return (
        <KpiVariable>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">DATA KPI Variabel</h3>
                            <span>List Data KPI Variabel</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <div className="table-responsive mb-3">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                        <span className="form-control fw-bold">No</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Kode KPI Variabel</span>
                                    </th>
                                    <th className="text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Tahun</span>
                                    </th>
                                    <th style={{width: '25%'}}>
                                        <span className="form-control fw-bold">Bidang</span>
                                    </th>
                                    <th style={{width: '30%'}}>
                                        <span className="form-control fw-bold">Variabel</span>
                                    </th>
                                    <th className="text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Bobot</span>
                                    </th>
                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Status</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    variable.length > 0 ?
                                    variable.map((data) => (
                                        <tr key={data.no}>
                                            <td className="text-center">{ data.no > 9 ? data.no : `0${data.no}` }</td>
                                            <td>{ data.code }</td>
                                            <td className="text-center">{ data.tahun }</td>
                                            <td>{ data.bidang }</td>
                                            <td>{ data.name }</td>
                                            <td className="text-center">{ data.bobot } %</td>
                                            <td className="text-center">
                                                {
                                                    data.status === 'bisnis' ?
                                                    <span className="badge bg-success">Bisnis</span> : 
                                                    <span className="badge bg-orange">Non Bisnis</span>
                                                }
                                            </td>
                                        </tr>
                                    )) : 
                                    <tr>
                                        <th colSpan={7} className="text-center">DATA TIDAK DITEMUKAN</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* pagination */}
                    <PageData 
                        name={'KPI Variabel'}
                        status={pagination.status}
                        total={pagination.total}
                        page={pagination.page}
                        per_page={pagination.per_page}
                        onChange={handlePagination}
                    />
                </div>
            </div>
        </KpiVariable>
    )
}

export default KpiVariableData
