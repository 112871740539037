import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../http";

const initialState = {}

export const getKpiIndicator = createAsyncThunk('kpi-indicator/getKpiIndicator', async(page) => {
    try {
        const response = await http.get(`/kpi/indicator?page=${page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getAllKpiIndicator = createAsyncThunk('kpi-indicator/getAllKpiIndicator', async() => {
    try {
        const response = await http.get('/kpi/indicator/all').catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const storeKpiIndicator = createAsyncThunk('kpi-indicator/storeKpiIndicator', async(data) => {
    try {
        const response = await http.post('/kpi/indicator/store', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getDetailKpiIndicator = createAsyncThunk('kpi-indicator/getDetailKpiIndicator', async(code) => {
    try {
        const response = await http.get(`/kpi/indicator/detail/${code}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const updateBobotKpiIndicator = createAsyncThunk('kpi-indicator/updateBobotKpiIndicator', async(data) => {
    try {
        const response = await http.post(`/kpi/indicator/update/bobot/${data.code}`, data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

const kpi_indicator_m = createSlice({
    name: 'kpi-indicator',
    initialState
})

export default kpi_indicator_m.reducer;