import React from 'react'
import KPiVariableForm from '../../views/kpi/variable/form/KPiVariableForm'
import KpiVariableData from '../../views/kpi/variable/data/KpiVariableData'
import KpiVariableEdit from '../../views/kpi/variable/edit/KpiVariableEdit'

function KpiVariableController({ view }) {
    if (view === 'form') {
        return (
            <KPiVariableForm />
        )
    } else if (view === 'edit') {
        return (
            <KpiVariableEdit />
        )
    } else {
        return (
            <KpiVariableData />
        )
    }
}

export default KpiVariableController
