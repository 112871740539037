import React from 'react'
import Dashboard from '../../views/dashboard/Dashboard'

function DashboardController() {
    return (
        <Dashboard />
    )
}

export default DashboardController
