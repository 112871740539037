import React from 'react'
import RkapForm from '../../views/rkap/form/RkapForm'
import RkapData from '../../views/rkap/data/RkapData'

function RkapController({ view }) {
    if (view === 'form') {
        return (
            <RkapForm />
        )
    } else {
        return (
            <RkapData />
        )
    }
}

export default RkapController
