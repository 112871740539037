import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../http";

const initialState = {}

export const getRkap = createAsyncThunk('rkap/getRkap', async(page) => {
    try {
        const response = await http.get(`/rkap?page=${page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const storeRkap = createAsyncThunk('rkap/storeRkap', async(data) => {
    try {
        const response = await http.post('/rkap/store', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

const rkap_m = createSlice({
    name: 'rkap',
    initialState
})

export default rkap_m.reducer;