import ArticleController from "../../controller/article/ArticleController"
import RkapController from "../../controller/rkap/RkapController"

const _kpm = [
    // rkap
    { path: 'rkap/data', element: RkapController, view: 'data' },

    // article note
    { path: 'article', element: ArticleController, view: 'article' },
    { path: 'article/:code', element: ArticleController, view: 'detail' },
]


export default _kpm