import { account, article, bidang, dashboard, kpi, login, rkap, user } from '../content/direksi/direksi_content'

const _direksi = [
    { category: 'login', label: 'Login', content: login },
    { category: 'account', label: 'My Account', content: account },
    { category: 'dashboard', label: 'Dashboard', content: dashboard },
    { category: 'bidang', label: 'Master Bidang', content: bidang },
    { category: 'user', label: 'Users', content: user },
    { category: 'kpi', label: 'KPI', content: kpi },
    { category: 'rkap', label: 'RKAP', content: rkap },
    { category: 'article', label: 'Catatan Dewas', content: article },
];

export default _direksi;