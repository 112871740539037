import React, { useState } from 'react'
import Layouts from '../layouts/Layouts'
import { decode, encode } from 'base-64'
import { avatar_default } from '../../images'
import { Button, TextField } from '@mui/material'
import { CameraAlt } from '@mui/icons-material'
import { Camera, CameraResultType } from '@capacitor/camera'
import { Loading, b64toBlob, imageResizer } from '../../../utils'
import { useDispatch } from 'react-redux'
import { updateProfileData, updateProfilePhoto } from '../../../model/profile/profile_m'
import toast from 'react-hot-toast'

const AccountSetting = () => {
    const dispatch = useDispatch();

    const photo = localStorage.getItem('photo')
    const name = localStorage.getItem('name')
    const posisi = localStorage.getItem('posisi')
    const jabatan = localStorage.getItem('jabatan')
    const code = localStorage.getItem('code')
    const code_access = localStorage.getItem('code_access')

    const [picture, setPicture] = useState('')

    const onTakePhoto = async() => {
        try {
            const camera = await Camera.getPhoto({
                quality: 80,
                allowEditing: true,
                resultType: CameraResultType.Base64
            })

            const image = camera.base64String;
            setPicture('data:image/png;base64,' + image);

            const blob = b64toBlob(image, 'image/png');
            const file = new File([blob], 'image.png', {
                type: blob.type,
                lastModified: new Date().getTime()
            })
            const resize = await imageResizer(file, 800, 800);
            const data = { code: decode(JSON.parse(code)), photo: resize }

            const response = await dispatch(updateProfilePhoto(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Foto Profil Berhasil Diperbaharui')
                localStorage.removeItem('photo');
                localStorage.setItem('photo', JSON.stringify(encode(response.photo)))
                setTimeout(() => {
                    return window.location.reload()
                }, 1200);
            }

        } catch (error) {
            
        }
    }

    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState({
        name: decode(JSON.parse(name)), posisi: decode(JSON.parse(posisi)), jabatan: decode(JSON.parse(jabatan)),  
        username: decode(JSON.parse(code_access)), password: ''
    })

    const onUpdate = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const data = {code: decode(JSON.parse(code)) ,...input}
            const response = await dispatch(updateProfileData(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                const result = response.data
                toast.success('Personal Data Berhasil Diperbaharui')
                localStorage.removeItem('code_access');
                localStorage.removeItem('name');
                localStorage.removeItem('posisi');
                localStorage.removeItem('jabatan');

                localStorage.setItem('code_access', JSON.stringify(encode(result.username)));
                localStorage.setItem('name', JSON.stringify(encode(result.name)));
                localStorage.setItem('posisi', JSON.stringify(encode(result.posisi)));
                localStorage.setItem('jabatan', JSON.stringify(encode(result.jabatan)));
                setTimeout(() => {
                    return window.location.replace(`/${result.username}/my-account`)
                }, 1200);
            }

            if (response.status === 400) {
                toast.error('Periksa Kembali Data Anda')
                setError(response.message)
                setLoading(false)
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }

    return (
        <Layouts>
            { loading ? <Loading /> : '' }
            <form onSubmit={onUpdate}>
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center" style={{marginBottom: '10%'}}>
                                    <div>
                                        <img src={ picture ? picture : picture === '' && decode(JSON.parse(photo)) !== 'null' ? decode(JSON.parse(photo)) : avatar_default } alt="" style={{height: 350, width: 350, boxShadow: '1px 1px 5px #000000a1'}} className="rounded-circle" />
                                    </div>
                                    <div style={{marginTop: '-15%'}}>
                                        <Button onClick={onTakePhoto} variant="contained" color="success" size="small" startIcon={ <CameraAlt /> }>
                                            Update Photo
                                        </Button>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-uppercase">{ decode(JSON.parse(name)) }</h2>
                                    <div>
                                        <span style={{fontSize: 16}}>{ decode(JSON.parse(posisi)) }</span>
                                    </div>
                                    <div>
                                        <span style={{fontSize: 16}}>( { decode(JSON.parse(jabatan)) } )</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Nama Lengkap <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Nama Lengkap"
                                        value={input.name}
                                        onChange={(e) => setInput({...input, name: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Posisi <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Posisi"
                                        value={input.posisi}
                                        onChange={(e) => setInput({...input, posisi: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Jabatan <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Jabatan"
                                        value={input.jabatan}
                                        onChange={(e) => setInput({...input, jabatan: e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Username <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Username"
                                        value={input.username}
                                        onChange={(e) => setInput({...input, username: e.target.value.toLocaleLowerCase().trim()})}
                                        required
                                    />
                                    <small className="text-danger">{ error.username ? error.username : '' }</small>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Tulis Password"
                                        value={input.password}
                                        onChange={(e) => setInput({...input, password: e.target.value.toLowerCase().trim()})}
                                    />
                                </div>
                                <div>
                                    <Button sx={{padding: '10px 14px'}} fullWidth variant="contained" color="success" type="submit">
                                        Update Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Layouts>
    )
}

export default AccountSetting
