import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../http";

const initialState = {}

export const getKpiVariable = createAsyncThunk('kpi-variable/getKpiVariable', async(page) => {
    try {
        const response = await http.get(`/kpi/variable?page=${page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const storeKpiVariable = createAsyncThunk('kpi-variable/storeKpiVariable', async(data) => {
    try {
        const response = await http.post('/kpi/variable/store', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getIndicatorStatusKpiVariable = createAsyncThunk('kpi-variable/getIndicatorStatusKpiVariable', async(data) => {
    try {
        const response = await http.get(`/kpi/variable/get-indicator-status/${data.code_indicator}/${data.status}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const updateBobotKpiVariable = createAsyncThunk('kpi-variable/updateBobotKpiVariable', async(data) => {
    try {
        const response = await http.post('/kpi/variable/update/bobot', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getByCodeBidangKpiVariable = createAsyncThunk('kpi-variable/getByCodeBidangKpiVariable', async(code_bidang) => {
    try {
        const response = await http.get(`/kpi/variable/get-by-code-bidang/${code_bidang}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

const kpi_variable_m = createSlice({
    name: 'kpi-variable',
    initialState
})

export default kpi_variable_m.reducer;