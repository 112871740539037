import React, { useEffect, useState } from 'react'
import Rkap from '../Rkap'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getAllKpiIndicator } from '../../../model/kpi/kpi_indicator_m'
import { getIndicatorStatusKpiVariable } from '../../../model/kpi/kpi_variable_m'
import { Loading, NumericFormatCustom } from '../../../utils'
import toast from 'react-hot-toast'
import { NumericFormat } from 'react-number-format';
import { storeRkap } from '../../../model/rkap/rkap_m'
import { useParams } from 'react-router-dom'

const status = [
    { code: 'bisnis', label: 'Bisnis' },
    { code: 'non', label: 'Non Bisnis' }
]

const status_rkap = [
    { code: 'penjualan', label: 'Penjualan' },
    { code: 'pembelian', label: 'Pembelian' },
    { code: 'biaya_product', label: 'Biaya Produk' },
    { code: 'biaya_non_product', label: 'Biaya Non Produk' },
    { code: 'biaya_lain', label: 'Biaya Lain Lain' },
    { code: 'pendapatan_lain', label: 'Pendapatan Lain Lain' }
]

const RkapForm = () => {
    const dispatch = useDispatch()
    const { username } = useParams()

    const [bidang, setBidang] = useState([])
    const [variabel, setVairable] = useState([])

    const getDataBidang = async() => {
        try {
            const response = await dispatch(getAllKpiIndicator()).unwrap().catch((err) => {})
            setBidang(response.data.map((data) => ({code: data.code, code_bidang: data.code_bidang, label: data.bidang})))
        } catch (error) {
            
        }
    }

    const getVariable = async(data) => {
        try {
            const response = await dispatch(getIndicatorStatusKpiVariable(data)).unwrap().catch((err) => {})
            setVairable(response.data.filter(obj => obj.used === 'tidak').map((data) => ({code: data.code, label: data.name})))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataBidang()
    }, [])
    const date = new Date();
    const codeDate = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

    const [input, setInput] = useState({
        code_indicator: '', code_bidang: '', bidang: '', code_variable: '', variable: '', 
        status: '', status_label: '', lock: false,
    })
    const [add, setAdd] = useState({
        name: '', target: '', status_rkap: '', status_rkap_label: ''
    })
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    const handleChangeBidang = (_, event) => {
        if (event) {
            setInput({
                ...input, 
                code_indicator: event.code, code_bidang: event.code_bidang, bidang: event.label,
                status: '', status_label: '', code_variable: '', variable: ''
            })
        }
    }

    const handleChangeStatus = (_, event) => {
        if (event) {
            setInput({...input, status: event.code, status_label: event.label, code_variable: '', variable: ''})
            const data = { code_indicator: input.code_indicator, status: event.code }
            return getVariable(data)
        }
    }

    const handleChangeVariable = (_, event) => {
        if (event) {
            setInput({...input, code_variable: event.code, variable: event.label})
        }
    }

    const onLockData = async()=> {
        setInput({...input, lock: true})
    }
    const onUnlockData = async() => {
        setInput({
            ...input, code_indicator:'', code_bidang:'', bidang: '', code_variable: '',
            variable: '', status: '', status_label: '', lock: false
        })
        setAdd({name: '', target: '', status_rkap: '', status_rkap_label: ''})
        setList([])
    }

    // uraian
    const onAddData = async() => {
        if (add.name === '' || add.target === '' || add.status_rkap === '') {
            toast.error('Uraian, Target RKAP, dan Status Wajib Diisi');
        } else {
            setList([...list, {
                code: `RK${codeDate}`,
                tahun: date.getFullYear(),
                code_bidang: input.code_bidang,
                code_indicator: input.code_indicator,
                code_variable: input.code_variable,
                bidang: input.bidang,
                variable: input.variable,
                status: input.status,
                name: add.name,
                target: add.target,
                status_rkap: add.status_rkap
            }])
            setAdd({name: '', target: '', status_rkap: '', status_rkap_label: ''})
        }
    }

    const onDeleteData = (index) => {
        const array = Array.from(list)
        array.splice(index, 1)
        setList(array)
    }

    const total_target = list.reduce((i, j) => i + parseFloat(j.target), 0)

    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const data = {list: {...list}, total: total_target}
            const response = await dispatch(storeRkap(data)).unwrap().catch((err) => {})
            if (response.status === 201) {
                toast.success('RKAP Berhasil Disimpan')
                setTimeout(() => {
                    return window.location.href = `/${username}/rkap/data`
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    return (
        <Rkap>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">INPUT RKAP</h3>
                            <span>Form Input RKAP</span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-9 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-end" style={{marginRight: 0}}>
                                    <div className="col-md-4 mb-3">
                                        <label className="form-label">Pilih Bidang <span className="text-danger">*</span></label>
                                        <Autocomplete 
                                            disabled={ input.lock ? true : false }
                                            size="small"
                                            fullWidth
                                            disableClearable
                                            options={bidang}
                                            value={input.bidang}
                                            renderInput={(params) => <TextField {...params} placeholder="Pilih Bidang" required />}
                                            onChange={handleChangeBidang}
                                        />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Pilih Status <span className="text-danger">*</span></label>
                                        <Autocomplete 
                                            disabled={ input.code_indicator && input.lock === false ? false : input.code_indicator && input.lock ? true : true }
                                            size="small"
                                            fullWidth
                                            disableClearable
                                            options={status}
                                            value={input.status_label}
                                            renderInput={(params) => <TextField {...params} placeholder="Pilih Status" />}
                                            onChange={handleChangeStatus}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label className="form-label">Pilih Variabel <span className="text-danger">*</span></label>
                                        <Autocomplete 
                                            disabled={ input.status && input.lock === false ? false : input.status && input.lock ? true : true }
                                            size="small"
                                            fullWidth
                                            disableClearable
                                            options={variabel}
                                            value={input.variable}
                                            renderInput={(params) => <TextField {...params} placeholder="Pilih Variabel" />}
                                            onChange={handleChangeVariable}
                                        />
                                    </div>
                                    <div className="col-md-1 mb-3">
                                        {
                                            input.lock ? 
                                            <Button onClick={onUnlockData} sx={{padding: '7.5px 14px'}} fullWidth variant="contained" color="error">
                                                Unlock
                                            </Button> : 
                                            <Button onClick={onLockData} disabled={ input.code_variable ? false : true } sx={{padding: '7.5px 14px'}} fullWidth variant="contained" color="primary">
                                                Lock
                                            </Button>
                                        }
                                    </div>

                                    <div className="col-md-5 mb-3">
                                        <label className="form-label">Uraian RKAP</label>
                                        <TextField 
                                            disabled={ input.lock ? false : true }
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Uraian RKAP"
                                            value={add.name}
                                            onChange={(e) => setAdd({...add, name: e.target.value})}
                                        />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Target RKAP</label>
                                        <TextField 
                                            disabled={ input.lock ? false : true }
                                            size="small"
                                            fullWidth
                                            placeholder="Tulis Target RKAP"
                                            value={add.target}
                                            onChange={(e) => setAdd({...add, target: e.target.value})}
                                            InputProps={{
                                                inputComponent: NumericFormatCustom,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Status</label>
                                        <Autocomplete 
                                            disabled={ input.lock ? false : true }
                                            size="small"
                                            fullWidth
                                            disableClearable
                                            options={status_rkap}
                                            value={add.status_rkap_label}
                                            renderInput={(params) => <TextField {...params} placeholder="Pilih Status" />}
                                            onChange={(_, event) => setAdd({...add, status_rkap: event.code, status_rkap_label: event.label})}
                                        />
                                    </div>
                                    <div className="col-md-1 mb-3">
                                        <Button onClick={onAddData} disabled={ input.lock ? false : true } fullWidth variant="contained" color="success">
                                            Add
                                        </Button>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                            <tr>
                                                <th className="table-border-start text-center" style={{width: '5%'}}>
                                                    <span className="form-control fw-bold">No</span>
                                                </th>
                                                <th style={{width: '15%'}}>
                                                    <span className="form-control fw-bold">Kode Uraian</span>
                                                </th>
                                                <th style={{width: '30%'}}>
                                                    <span className="form-control fw-bold">Uraian RKAP</span>
                                                </th>
                                                <th>
                                                    <span className="form-control fw-bold">Target RKAP</span>
                                                </th>
                                                <th className="text-center" style={{width: '15%'}}>
                                                    <span className="form-control fw-bold">Status</span>
                                                </th>
                                                <th className="table-border-end text-center" style={{width: '10%'}}>
                                                    <span className="form-control fw-bold">Action</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                list.length > 0 ?
                                                list.map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center">
                                                            { index + 1 > 9 ? index + 1 : `0${index + 1}` }
                                                        </td>
                                                        <td>{ data.code }</td>
                                                        <td>{ data.name }</td>
                                                        <td>
                                                            <NumericFormat 
                                                                value={ data.target } 
                                                                allowLeadingZeros 
                                                                thousandSeparator="," 
                                                                valueIsNumericString
                                                                prefix="Rp. "
                                                                displayType="text"
                                                                renderText={(value) => <span>{value}</span>}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                data.status_rkap === 'penjualan' ? 'Penjualan' : 
                                                                data.status_rkap === 'pembelian' ? 'Pembelian' :
                                                                data.status_rkap === 'biaya_product' ? 'Biaya Produk' :
                                                                data.status_rkap === 'biaya_non_product' ? 'Biaya Non Produk' :
                                                                data.status_rkap === 'biaya_lain' ? 'Biaya Lain Lain' :
                                                                'Pendapatan Lain Lain'
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            <Button onClick={() => onDeleteData(index)} size="small" variant="contained" color="error">
                                                                Delete
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )) : 
                                                <tr>
                                                    <th colSpan={6} className="text-center">DATA TIDAK DITEMUKAN</th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-price mb-3">
                                    <div className="d-flex justify-content-between align-items-center mb-2 card-price-title">
                                        <span>
                                            Total Target { input.variable }
                                        </span>
                                    </div>

                                    <div className="text-center price-total" style={{fontSize: '20px'}}>
                                        <NumericFormat 
                                            value={ total_target } 
                                            allowLeadingZeros 
                                            thousandSeparator="," 
                                            valueIsNumericString
                                            prefix="Rp. "
                                            displayType="text"
                                            renderText={(value) => <span>{value}</span>}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button disabled={ list.length > 0 ? false : true } variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Rkap>
    )
}

export default RkapForm
