import React from 'react'
import Header from './header/Header'
import Footer from './footer/Footer'
import { Toaster } from 'react-hot-toast'
import Verified from '../verified/Verified'

const Layouts = ({ children }) => {
    return (
        <Verified>
            <div className="page">
                <Toaster />
                {/* Header */}
                <Header />

                <div className="page-wrapper" style={{backgroundColor: '#ffffff'}}>
                    <div className="container-fluid">
                        <div className="page-body">
                            { children }
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </Verified>
    )
}

export default Layouts
