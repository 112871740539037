import React from 'react'

const Footer = () => {
    return (
        <footer className="footer footer-web footer-transparent bg-success d-print-none">
            <div className="container-xl">
                <div className="row text-center align-items-center justify-content-center">
                    <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                        <ul className="list-inline list-inline-dots mb-0">
                            <li className="list-inline-item">
                                <span className="fs-5">LEADERSHIP PERUMDA ANEKA USAHA JEPARA © 2024</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
