import React, { useEffect, useState } from 'react'
import KpiScore from '../KpiScore'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getAllKpiIndicator } from '../../../../model/kpi/kpi_indicator_m'
import { Loading, codeDate } from '../../../../utils'
import { storeKpiScore } from '../../../../model/kpi/kpi_score_m'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

function handleGrade(grade) {
    if (! Number(grade)) {
        return 0
    } else {
        if (grade > 100) {
            return ''
        } else {
            return parseInt(grade)
        }
    }
}

const KpiScoreForm = () => {
    const dispatch = useDispatch()
    const { username } = useParams()
    
    const [bidang, setBidang] = useState([])
    const getIndicator = async() => {
        try {
            const response = await dispatch(getAllKpiIndicator()).unwrap().catch((err) => {})
            setBidang(response.data.map((data) => ({code: data.code_bidang, label: data.bidang})))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getIndicator()
    }, [])

    const [input, setInput] = useState({
        code: `SC${codeDate}`, code_bidang: '', bidang: '', grade_a: '', grade_b: '', grade_c: '', grade_d: '',
        grade_e: '', desc: ''
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const handleChangeBidang = (_, event) => {
        if (event) {
            setInput({...input, code_bidang: event.code, bidang: event.label})
        }
    }

    const handleChangeGradeA = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_a: grade})
    }
    const handleChangeGradeB = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_b: grade})
    }
    const handleChangeGradeC = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_c: grade})
    }
    const handleChangeGradeD = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_d: grade})
    }
    const handleChangeGradeE = (e) => {
        const value = e.target.value;
        const grade = handleGrade(value)
        setInput({...input, grade_e: grade})
    }

    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const data = {...input}
            const response = await dispatch(storeKpiScore(data)).unwrap().catch((err) => {})
            if (response.status === 201) {
                toast.success('KPI Skor Berhasil Disimpan')
                setTimeout(() => {
                    return window.location.href = `/${username}/kpi/score/data`
                }, 1200);
            }

            if (response.status === 400) {
                setError(response.message)
                toast.error('Periksa Kembali Data Yang Anda Inputkan')
                setLoading(false)
                setTimeout(() => {
                    setError([])
                },2000);
            }
        } catch (error) {
            
        }
    }
    
    return (
        <KpiScore>
            { loading ? <Loading /> : '' }
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">Input KPI Skor</h3>
                            <span>Form Input KPI Skor</span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit} className="mt-3">
                <div className="row" style={{marginRight: 0}}>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="form-label">Bidang <span className="text-danger">*</span></label>
                                    <Autocomplete 
                                        size="small"
                                        fullWidth
                                        disableClearable
                                        options={bidang}
                                        value={input.bidang}
                                        renderInput={(params) => <TextField {...params} error={ error.code_bidang ? true : false } placeholder="Pilih Bidang" required />}
                                        onChange={handleChangeBidang}
                                    />
                                    <small className="text-danger">{ error.code_bidang ? error.code_bidang : '' }</small>
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Grade A ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade A"
                                        value={input.grade_a}
                                        onChange={handleChangeGradeA}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="from-label">Grade B ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade B"
                                        value={input.grade_b}
                                        onChange={handleChangeGradeB}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="from-label">Grade C ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade C"
                                        value={input.grade_c}
                                        onChange={handleChangeGradeC}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="from-label">Grade D ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade D"
                                        value={input.grade_d}
                                        onChange={handleChangeGradeD}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="from-label">Grade E ( Skor Minimal ) <span className="text-danger">*</span></label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        placeholder="Skor Minimal Grade E"
                                        value={input.grade_e}
                                        onChange={handleChangeGradeE}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label className="from-label">Keterangan</label>
                                    <textarea style={{height: 140}} placeholder="Keterangan" onChange={(e) => setInput({...input, desc: e.target.value})} className="form-control"></textarea>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button variant="contained" color="primary" type="submit">
                                        Simpan Data
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </KpiScore>
    )
}

export default KpiScoreForm
