import { AccountTree, Article, EventNote, GridView, Groups, RuleFolder, SaveAs } from "@mui/icons-material"
const _admin = [
    {
        name: 'Dashboard',
        icon: GridView,
        to: 'dashboard',
        active: 'dashboard'
    },
    {
        name: 'Master Bidang',
        icon: RuleFolder,
        to: 'bidang/data',
        active: 'bidang'
    },
    {
        name: 'Users',
        icon: Groups,
        to: 'user/data',
        active: 'user'
    },
    {
        name: 'KPI',
        icon: AccountTree,
        to: 'kpi/indicator/data',
        active: 'kpi'
    },
    {
        name: 'RKAP',
        icon: SaveAs,
        to: 'rkap/data',
        active: 'rkap'
    },
    {
        name: 'Buat Catatan',
        icon: EventNote,                                                                                                                                                                                                                                                                                                                      
        to: 'note/data',
        active: 'note'
    },
    {
        name: 'Catatan Dewas',
        icon: Article,                                                                                                                                                                                                                                                                                                                      
        to: 'article',
        active: 'article'
    },
]

export default _admin