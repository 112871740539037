import React, { useState } from 'react'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { bg_login, logo } from '../images'
import { AccountCircle, LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { encode } from 'base-64'
import { Loading } from '../../utils'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../model/login/login_m'

const Login = () => {
    const dispatch = useDispatch()

    const [input, setInput] = useState({
        username: '', password: ''
    })
    const [error, setError] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [loading, setLoading] = useState(false)
    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const data = {...input}
            const response = await dispatch(loginUser(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Akses Anda Terverifikasi')
                const result = response.data;
                localStorage.setItem('code_access', JSON.stringify(encode(result.username)));
                localStorage.setItem('name', JSON.stringify(encode(result.name)));
                localStorage.setItem('posisi', JSON.stringify(encode(result.posisi)));
                localStorage.setItem('jabatan', JSON.stringify(encode(result.jabatan)));
                localStorage.setItem('photo', JSON.stringify(encode(result.photo)));
                localStorage.setItem('code', JSON.stringify(encode(result.code)));
                localStorage.setItem('type', JSON.stringify(encode(result.type)));
                setTimeout(() => {
                    return window.location.href = `/${result.username}/screen-welcome`
                }, 1200);
            }

            if (response.status === 400) {
                toast.error('Periksa Kembali Username / Password Anda')
                setLoading(false)
                setError(response.message)
                setTimeout(() => {
                    setError([])
                }, 2000);
            }
        } catch (error) {
            
        }
    }
    return (
        <div>
            <Toaster position="top-center" />
            { loading ? <Loading /> : '' }
            <div className="row g-0 flex-fill">
                <div className="col-12 col-lg-6 col-xl-8 d-none d-lg-block">
                    <div className="bg-cover h-100 min-vh-100" style={{ backgroundImage: `url(${bg_login})`, filter: 'brightness(60%)' }}></div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4 border-top-wide border-primary d-flex flex-column justify-content-center">
                    <div className="container container-tight my-5 px-lg-5">
                        <div className="login-mobile">
                            <div className="text-center mb-2">
                                <a href="" className="navbar-brand navbar-brand-autodark">
                                    <img src={ logo } alt="" style={{filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)'}} />
                                </a>
                            </div>
                            <h2 className="h3 text-center mb-1">
                                Login to your account
                            </h2>
                            <div className="text-center mb-3">LEADERSHIP DASHBOARD PERUMDA ANEKA USAHA KABUPATEN JEPARA</div>
                            <form onSubmit={onSubmit}>
                                <div className="mb-2">
                                    <label className="form-label form-label-m-0">Username</label>
                                    <TextField 
                                        error={ error.username ? true : false }
                                        size="small"
                                        fullWidth
                                        value={input.username}
                                        placeholder="Masukkan Username"
                                        InputProps={{
                                            startAdornment: ( <InputAdornment position="start"> <AccountCircle /> </InputAdornment> ),
                                        }}
                                        onChange={(e) => setInput({...input, username: e.target.value.trim().toLowerCase()})}
                                        required
                                    />
                                    <small className="text-danger">{ error.username ? error.username : '' }</small>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label form-label-m-0">Password</label>
                                    <TextField 
                                        size="small"
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Masukkan Password"
                                        InputProps={{
                                            startAdornment: ( <InputAdornment position="start"> <LockOutlined /> </InputAdornment> ),
                                            endAdornment: (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            )
                                        }}
                                        onChange={(e) => setInput({...input, password: e.target.value})}
                                        required
                                    />
                                </div>
                                <div>
                                    <Button fullWidth variant="contained" color="primary" type="submit">
                                        Login
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
