import React from 'react'
import AccountSetting from '../../views/screen/account/AccountSetting'

function AccountSettingController() {
    return (
        <AccountSetting />
    )
}

export default AccountSettingController
