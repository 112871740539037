import React from 'react'
import { decode } from 'base-64';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Loading } from '../../utils';

const Verified = (props) => {
    const code_access = localStorage.getItem('code_access');
    const { username } = useParams()
    const [loading, setLoading] = useState(false)
    const [verified, setVerified] = useState(false)

    const checkData = () => {
        if (! code_access || code_access === '' || code_access == null) {
            setLoading(true)
            alert('Maaf Anda Tidak Memiliki Akses')
            setTimeout(() => {
                return window.location.href = '/'
            }, 1200);
        } else {
            if (username !== decode(JSON.parse(code_access))) {
                setLoading(true)
                alert('Maaf Anda Tidak Memiliki Akses')
                setTimeout(() => {
                    return window.location.href = '/'
                }, 1200);
            } else {
                setVerified(true)
            }
        }
    }

    useEffect(() => {
        checkData()
    }, [verified])
    return (
        <React.Fragment>
            { loading ? <Loading /> : '' }
            { verified ? props.children : '' }
        </React.Fragment>
    )
}

export default Verified
