import React from 'react'
import ScreenWelcome from '../../views/screen/ScreenWelcome'

function ScreenController() {
    return (
        <ScreenWelcome />
    )
}

export default ScreenController
