import { account, article, dashboard, kpi, login, rkap } from '../content/spi/spi_content'

const _spi = [
    { category: 'login', label: 'Login', content: login },
    { category: 'account', label: 'My Account', content: account },
    { category: 'dashboard', label: 'Dashboard', content: dashboard },
    { category: 'kpi', label: 'KPI', content: kpi },
    { category: 'rkap', label: 'RKAP', content: rkap },
    { category: 'article', label: 'Catatan Dewas', content: article },
]

export default _spi