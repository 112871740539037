import React, { useEffect, useState } from 'react'
import Layouts from '../layouts/Layouts'
import BarChart from './charts/BarChart'
import ComparasiChart from './charts/ComparasiChart'
import { CurrencyExchange, ExpandLess, ExpandMore, Grass, Handshake, LocalGasStation, ShoppingBag, ShoppingCart } from '@mui/icons-material'
import { Button, LinearProgress, ThemeProvider, Typography, createTheme } from '@mui/material'
import { image1, logo, noimage, perumda_icon } from '../images'
import ReactSpeedometer from 'react-d3-speedometer'
import { useDispatch } from 'react-redux'
import { getByCodeBidangKpiVariable } from '../../model/kpi/kpi_variable_m'
import { CurrentFormat } from '../../utils'
import { getByCodeBidangKpiScore } from '../../model/kpi/kpi_score_m'
import Grade from './grade/Grade'
import { getLimitNote } from '../../model/note/note_m'
import Moment from 'react-moment'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllBidang } from '../../model/bidang/bidang_m'
import toast from 'react-hot-toast'

const theme = createTheme({
    palette: {
        green: {
            main: '#00a50e'
        },
        orange: {
            main: '#fe8e0e'
        },
        red: {
            main: '#c40020',
        },
    },
});

const Dashboard = () => {
    const dispatch = useDispatch();
    const { username } = useParams()
    const navigate = useNavigate()

    const [variable, setVariable] = useState([])
    const [score, setScore] = useState([])
    const [percent, setPercent] = useState(0)

    const [note, setNote] = useState([])
    const [bidang, setBidang] = useState([])

    const getDataVariable = async(code_bidang) => {
        try {
            const response = await dispatch(getByCodeBidangKpiVariable(code_bidang)).unwrap().catch((err) => {})
            if (response.status === 200) {
                const result = response.data;
                setVariable(result)
                const total_target_data = result.reduce((i, j) => i + parseFloat(j.target), 0);
                const total_realisasi_data = result.reduce((i, j) => i + parseFloat(j.realisasi), 0);
                const percentdata = (total_realisasi_data / total_target_data) * 100;
                setPercent(percentdata)
            }
        } catch (error) {
            
        }
    }

    const getScore = async(code_bidang) => {
        try {
            const response = await dispatch(getByCodeBidangKpiScore(code_bidang)).unwrap().catch((err) => {})
            if (response.status === 200) {
                setScore(response.data)
            }

            if (response.status === 404) {
                setScore([])
            }
        } catch (error) {
            
        }
    }

    const getNote = async() => {
        try {
            const response = await dispatch(getLimitNote()).unwrap().catch((err) => {})
            if (response.status === 200) {
                setNote(response.data)
            }
        } catch (error) {
            
        }
    }

    const getBidang = async() => {
        try {
            const response = await dispatch(getAllBidang()).unwrap().catch((err) => {})
            if (response.status === 200) {
                setBidang(response.data)
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataVariable('DB2024218151514')
        getScore('DB2024218151514')
        getNote()
        getBidang()
    }, [])

    const [more, setMore] = useState(false)
    const [moreRkap, setMoreRkap] = useState(false);

    const onDetail = async(slug) => {
        return navigate(`/${username}/article/${slug}`)
    }

    const onShowData = async(code, name) => {
        if (code !== 'DB2024218151514') {
            toast.error(`Maaf Fitur ${name} Belum Tersedia`)
        } else {
            toast.success(`Data ${name} Sudah Tersedia`)
        }
    }   

    const onMoreNote = () => {
        return navigate(`/${username}/article`)
    }

    return (
        <Layouts>
            <ThemeProvider theme={theme}>
                <div className="row g-5" style={{ marginRight: 0 }}>
                    <div className="col-md-9 mb-3">
                        <div className="row" style={{ marginRight: 0 }}>

                            <div className="col-md-12 mb-3">
                                <div className="row row-deck" style={{ marginRight: 0 }}>
                                    <div className="col-md-6">
                                        <div className="card" style={{background: 'none', boxShadow: 'none', border: 'none'}}>
                                            <div className="mb-2">
                                                <div style={{ backgroundColor: '#10ad92', borderRadius: 4 }} className="text-center pt-4 pb-3">
                                                    <span style={{ fontSize: 40, fontWeight: 'bold', color: '#ffffff' }} className="mt-1">KPI DASHBOARD</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="card-in text-center" style={{lineHeight: 1, padding: '25px 0'}}>
                                                    <span style={{ fontSize: 20, fontWeight: 'bold' }}>BIDANG PERDAGANGAN BARANG & JASA <br /> <span style={{fontSize: 14, fontWeight: 'normal'}}>PERUMDA ANEKA USAHA KABUPATEN JEPARA</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="card-in" style={{padding: '0 0 .6rem 0'}}>
                                            <div className="text-center">
                                                <ReactSpeedometer
                                                    segments={5}
                                                    width={300}
                                                    height={160}
                                                    minValue={0}
                                                    maxValue={100}
                                                    needleHeightRatio={0.8}
                                                    currentValueText="ACHIEVMENT"
                                                    value={percent}
                                                    customSegmentStops={[0, 20, 40, 60, 80, 100 ]}
                                                    segmentColors={['#ff3201', '#fc9a00', '#fece00', '#8dd200', '#1abf01']}
                                                    customSegmentLabels={[
                                                        {
                                                            text: 'BAD',
                                                            position: 'INSIDE',
                                                            color: '#FFFFFF',
                                                        },
                                                        {
                                                            text: 'POOR',
                                                            position: 'INSIDE',
                                                            color: '#FFFFFF',
                                                        },
                                                        {
                                                            text: 'FAIR',
                                                            position: 'INSIDE',
                                                            color: '#FFFFFF',
                                                        },
                                                        {
                                                            text: 'GOOD',
                                                            position: 'INSIDE',
                                                            color: '#FFFFFF',
                                                        },
                                                        {
                                                            text: 'EXCELLENT',
                                                            position: 'INSIDE',
                                                            color: '#FFFFFF',
                                                        },
                                                    ]}
                                                    ringWidth={50}
                                                    needleTransitionDuration={3333}
                                                    needleTransition="easeElastic"
                                                    needleColor={'#333333'}
                                                    textColor={'#d8dee9'}
                                                    labelFontSize={'10px'}
                                                />
                                                <div style={{lineHeight: 0.9}} className="mt-1">
                                                    <span style={{fontSize: 14, fontWeight: 'bold'}}>ACHIEVMENT</span> <br />
                                                    <small style={{fontSize: 10}}>PROSENTASE PROGRESS { percent.toFixed(2) }% </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="card-in">
                                            <Grade percent={percent} indicator={score} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div>
                                            <h3>GRAFIK PERBANDINGAN RKAP TAHUN 2024 DENGAN REALISASI 2024 <br /> PERUMDA ANEKA USAHA JEPARA</h3>
                                        </div>
                                        <BarChart data={variable} />
                                    </div>
                                </div>
                            </div>
                            
                            {/* chart comparasi */}
                            {
                                variable.filter(obj => obj.status === 'bisnis').map((data, index) => (
                                    <div className="col-md-12 mb-3" key={index}>
                                        <div className="card">
                                            <div className="px-4">
                                                <div className="row align-items-start" style={{ marginRight: 0 }}>
                                                    <div className="col-md-4 mb-3">
                                                        <ComparasiChart target={parseFloat(data.target)} realisasi={parseFloat(data.realisasi)} />
                                                    </div>
                                                    <div className="col-md-8 mb-3">
                                                        <div className="table-responsive mt-3">
                                                            <table className="table align-middle table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="table-border-start px-3" style={{ width: '38%' }}>
                                                                            <span className="form-control shadow-form-control text-uppercase fw-bold" style={{fontSize: 18}}>{ data.name }</span>
                                                                        </th>
                                                                        <th className="table-border-end px-3">
                                                                            <span className="form-control shadow-form-control text-uppercase fw-bold" style={{fontSize: 18}}>BOBOT PRIORITAS {data.bobot}%</span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {
                                                                        data.rkap.filter(obj => obj.status_rkap === 'penjualan' || obj.status_rkap === 'pembelian').map((rkap, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div className="d-flex align-items-start">
                                                                                        <div className="bg-primary mt-1 me-2" style={{ width: 16, height: 16, borderRadius: 5 }}></div>
                                                                                        <div className="tbody-title">
                                                                                            <h4>{ rkap.name }</h4>
                                                                                            <small>
                                                                                                <b>RKAP 2024</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.target} />
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <LinearProgress color={ rkap.percent > 80 ? 'green' : rkap.percent > 50 && rkap.percent < 80 ? 'orange' : 'red'  } sx={{ mr: 1, height: 8, width: '100%', borderRadius: 4, backgroundColor: '#86848496'}} variant="determinate" value={ rkap.percent } />
                                                                                        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                                                            { rkap.percent.toFixed(1) }%
                                                                                        </Typography>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div>
                                                                                            <small> 
                                                                                                <b>REALISASI</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.realisasi} />
                                                                                            </small>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small>
                                                                                                <b>LEBIH/KURANG</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.realisasi - rkap.target} />
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    {
                                                                        more ? 
                                                                        data.rkap.filter(obj => obj.status_rkap === 'biaya_product').map((rkap, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div className="d-flex align-items-start">
                                                                                        <div className="bg-primary mt-1 me-2" style={{ width: 16, height: 16, borderRadius: 5 }}></div>
                                                                                        <div className="tbody-title">
                                                                                            <h4>{ rkap.name }</h4>
                                                                                            <small>
                                                                                                <b>RKAP 2024</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.target} />
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <LinearProgress color={ rkap.percent > 80 ? 'green' : rkap.percent > 50 && rkap.percent < 80 ? 'orange' : 'red'  } sx={{ mr: 1, height: 8, width: '100%', borderRadius: 4, backgroundColor: '#868485', '.css-t752vm': { backgroundColor: parseFloat(rkap.percent) > 60 && parseFloat(rkap.percent) <= 75 ? '#fa7c2f' : parseFloat(rkap.percent) > 75 ? '#00ba99' : '#e2135e'  } }} variant="determinate" value={ rkap.percent } />
                                                                                        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                                                            { rkap.percent.toFixed(1) }%
                                                                                        </Typography>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div>
                                                                                            <small> 
                                                                                                <b>REALISASI</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.realisasi} />
                                                                                            </small>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small>
                                                                                                <b>LEBIH/KURANG</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.realisasi - rkap.target} />
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )) : ''
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {
                                                            data.rkap.length > 4 ? 
                                                            <div className="text-center">
                                                                {
                                                                    more ? 
                                                                    <a href="#hide" className="btn-more" onClick={() => setMore(false)}>Sembunyikan <ExpandLess /></a> : 
                                                                    <a href="#more" className="btn-more" onClick={() => setMore(true)}>Lihat Detail <ExpandMore /></a>
                                                                }
                                                            </div> : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                moreRkap ? 
                                variable.filter(obj => obj.status === 'non').map((data, index) => (
                                    <div className="col-md-12 mb-3" key={index}>
                                        <div className="card">
                                            <div className="px-4">
                                                <div className="row align-items-start" style={{ marginRight: 0 }}>
                                                    <div className="col-md-4 mb-3">
                                                        <ComparasiChart target={parseFloat(data.target)} realisasi={parseFloat(data.realisasi)} />
                                                    </div>
                                                    <div className="col-md-8 mb-3">
                                                        <div className="table-responsive mt-3">
                                                            <table className="table align-middle table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="table-border-start px-3" style={{ width: '38%' }}>
                                                                            <span className="form-control shadow-form-control text-uppercase fw-bold" style={{fontSize: 18}}>{ data.name }</span>
                                                                        </th>
                                                                        <th className="table-border-end px-3">
                                                                            <span className="form-control shadow-form-control text-uppercase fw-bold" style={{fontSize: 18}}>BOBOT PRIORITAS {data.bobot}%</span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {
                                                                        data.rkap.map((rkap, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div className="d-flex align-items-start">
                                                                                        <div className="bg-primary mt-1 me-2" style={{ width: 16, height: 16, borderRadius: 5 }}></div>
                                                                                        <div className="tbody-title">
                                                                                            <h4>{ rkap.name }</h4>
                                                                                            <small>
                                                                                                <b>RKAP 2024</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.target} />
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <LinearProgress color={ rkap.percent > 80 ? 'green' : rkap.percent > 50 && rkap.percent < 80 ? 'orange' : 'red'  } sx={{ mr: 1, height: 8, width: '100%', borderRadius: 4, backgroundColor: '#86848496'}} variant="determinate" value={ rkap.percent } />
                                                                                        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                                                            { rkap.percent.toFixed(1) }%
                                                                                        </Typography>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div>
                                                                                            <small> 
                                                                                                <b>REALISASI</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.realisasi} />
                                                                                            </small>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small>
                                                                                                <b>LEBIH/KURANG</b> &nbsp;
                                                                                                <CurrentFormat currency={rkap.realisasi - rkap.target} />
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : ''
                            }
                            <div className="text-center">
                                {
                                    moreRkap ? 
                                    <a href="#hide" className="btn-more-blue" onClick={() => setMoreRkap(false)}>Hide Data</a> : 
                                    <a href="#more" className="btn-more-blue" onClick={() => setMoreRkap(true)}>Load More</a>
                                }
                            </div>

                            {/* chart comparasi end */}
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <img src={image1} alt="" style={{ width: '100%', borderRadius: 4 }} />
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="me-3">
                                        <img src={logo} height={60} width={200} alt="" />
                                    </div>
                                    <div style={{ lineHeight: 0.9 }} className="mt-1">
                                        <h4 style={{ lineHeight: 0.9 }}>PERUMDA ANEKA USAHA KABUPATEN JEPARA</h4>
                                        <small >Jl. Sidik Harun No.3, Ujungbatu II, Ujungbatu, Kec. Jepara, Kabupaten Jepara, Jawa Tengah 59416</small>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    {
                                        bidang.map((data, index) => (
                                            <div className="mb-2" key={index} style={{cursor: 'pointer'}} onClick={() => onShowData(data.code, data.name)}>
                                                <div className="card-in" style={{ padding: '.7rem' }}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-2 p-1" style={{ backgroundColor: '#00ba99', borderRadius: 5 }}>
                                                            {
                                                                index + 1 === 1 ? 
                                                                <img src={ perumda_icon } style={{height: 22, width: 22}} /> : 
                                                                index + 1 === 2 ? 
                                                                <ShoppingCart sx={{height: 22, width: 22, color: '#ffffff'}} /> : 
                                                                index + 1 === 3 ? 
                                                                <Grass sx={{height: 22, width: 22, color: '#ffffff'}} /> : 
                                                                index + 1 === 4 ? 
                                                                <CurrencyExchange sx={{height: 22, width: 22, color: '#ffffff'}} /> : 
                                                                index + 1 === 5 ? 
                                                                <LocalGasStation sx={{height: 22, width: 22, color: '#ffffff'}} /> : 
                                                                index + 1 === 6 ? 
                                                                <Handshake sx={{height: 22, width: 22, color: '#ffffff'}} /> :  ''
                                                            }
                                                        </div>
                                                        <div>
                                                            <span className="text-uppercase" style={{ fontSize: 16, fontWeight: 'bold' }}>{ data.name }</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div>
                                    <div className="mb-3">
                                        <h3 style={{ lineHeight: 0.8 }}>CATATAN DEWAN PENGAWAS</h3>
                                        <hr style={{ margin: 0, height: '2px', backgroundColor: '#00ba99', opacity: 1 }} />
                                    </div>

                                    {
                                        note.map((data, index) => (
                                            <div className="mb-2" key={index} onClick={() => onDetail(data.slug)}>
                                                <div className="card-in" style={{ padding: '.7rem', cursor: 'pointer' }}>
                                                    <div className="d-flex">
                                                        <div className="me-2">
                                                            <img src={ data.image ? data.image : noimage } alt="" width={80} height={80} style={{ borderRadius: 4 }} />
                                                        </div>
                                                        <div>
                                                            <span className="fw-bold">
                                                                {
                                                                    data.title.length > 15 ?
                                                                    `${data.title.substr(0, 15)} ...` : 
                                                                    data.title
                                                                }
                                                            </span> <br />
                                                            <small className="text-orange"> <Moment format="dddd, DD MMMM YYYY">{ data.date }</Moment> </small>
                                                            <div className="mt-2">
                                                                <span>Read More</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    <div>
                                        {
                                            note.length >= 5 ? 
                                            <Button onClick={onMoreNote} fullWidth size="small" color="primary" variant="contained" endIcon={ <ExpandMore /> }>
                                                Lihat Lebih Banyak
                                            </Button> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </Layouts>
    )
}

export default Dashboard
