import React from 'react'
import BidangForm from '../../views/bidang/form/BidangForm'
import BidangDataDetail from '../../views/bidang/data/BidangDataDetail'
import BidangData from '../../views/bidang/data/BidangData'

function BidangController({ view }) {
    if (view === 'form') {
        return (
            <BidangForm />
        )
    } else if (view === 'detail') {
        return (
            <BidangDataDetail />
        )
    } else {
        return (
            <BidangData />
        )
    }
}

export default BidangController
