import { account, article, bidang, dashboard, kpi, login, note, rkap, user } from "../content/admin/admin_content"

const _admin = [
    { category: 'login', label: 'Login', content: login },
    { category: 'account', label: 'My Account', content: account },
    { category: 'dashboard', label: 'Dashboard', content: dashboard },
    { category: 'bidang', label: 'Master Bidang', content: bidang },
    { category: 'user', label: 'Users', content: user },
    { category: 'kpi', label: 'KPI', content: kpi },
    { category: 'rkap', label: 'RKAP', content: rkap },
    { category: 'note', label: 'Buat Catatan Dewas', content: note },
    { category: 'article', label: 'Catatan Dewas', content: article },
]

export default _admin