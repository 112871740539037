import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../http";

const initialState = {}

export const getBidang = createAsyncThunk('bidang/getBidang', async(page) => {
    try {
        const response = await http.get(`/bidang?page=${page}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getAllBidang = createAsyncThunk('bidang/getAllBidang', async() => {
    try {
        const response = await http.get('/bidang/all').catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const storeBidang = createAsyncThunk('bidang/storeBidang', async(data) => {
    try {
        const response = await http.post('/bidang/store', data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const getDetailBidang = createAsyncThunk('bidang/getDetailBidang', async(code) => {
    try {
        const response = await http.get(`/bidang/detail/${code}`).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

export const updateBidang = createAsyncThunk('bidang/updateBidang', async(data) => {
    try {
        const response = await http.post(`/bidang/update/${data.code}`, data).catch((err) => {})
        return response.data
    } catch (error) {
        
    }
})

const bidang_m = createSlice({
    name: 'bidang',
    initialState
})

export default bidang_m.reducer;