import React, { useEffect, useState } from 'react'
import { account_icon, logo, rocket_icon, setting_icon } from '../images'
import { Logout } from '@mui/icons-material'
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Verified from '../verified/Verified';
import { decode } from 'base-64';
import { useDispatch } from 'react-redux';
import { logoutnUser } from '../../model/login/login_m';
import toast, { Toaster } from 'react-hot-toast';
import { Loading } from '../../utils';
import { getProfileUsername } from '../../model/profile/profile_m';

const ScreenWelcome = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { username } = useParams()

    const [loading, setLoading] = useState(false)
    const code_access = localStorage.getItem('code_access');
    const name = localStorage.getItem('name')
    const posisi = localStorage.getItem('posisi')

    const onLogout = async() => {
        setLoading(true)
        try {
            const data = { username: decode(JSON.parse(code_access)) }
            const response = await dispatch(logoutnUser(data)).unwrap().catch((err) => {})
            if (response.status === 200) {
                toast.success('Anda Berhasil Logout')
                localStorage.removeItem('code_access')
                localStorage.removeItem('name')
                localStorage.removeItem('code')
                localStorage.removeItem('posisi')
                localStorage.removeItem('jabatan')
                localStorage.removeItem('photo')
                localStorage.removeItem('type')
                setTimeout(() => {
                    return window.location.href = '/'
                }, 1200);
            }
        } catch (error) {
            
        }
    }

    const goApp = async() => {
        return navigate(`/${username}/dashboard`)
    }

    return (
        <Verified>
            <Toaster position="top-center" />
            { loading ? <Loading /> : '' }
            <div className="welcome-screen">
                <header className="welcome-screen-header">
                    <div className="header-content">
                        <div>
                            <img src={ logo } alt="" />
                        </div>

                        <div onClick={onLogout} className="text-center" style={{cursor: 'pointer'}}>
                            <Logout sx={{color: '#ffffff'}} /> 
                            <div className="text-white">
                                Logout
                            </div>
                        </div>
                    </div>
                </header>

                <div className="welcome-screen-content">
                    <div className="desc">
                        <div className="text-center">
                            <div className="mb-2">
                                <span className="text-white fw-bold" style={{fontSize: 18}}>SELAMAT DATANG</span>
                            </div>
                            <div className="mb-3">
                                <span className="name">{ decode(JSON.parse(name)) }</span>
                            </div>
                            <div className="status text-uppercase mb-4" style={{letterSpacing: '2px'}}>
                                <div>
                                    <div className="mb-3">
                                        { decode(JSON.parse(posisi)) }
                                    </div>
                                    <div>
                                        PERUMDA ANEKA USAHA <br /> KABUPATEN JEPARA
                                    </div>
                                </div>
                            </div>
                            <div className="text-white">
                                <span style={{fontWeight: 200, letterSpacing: 2}}>Semoga hari Anda menyenangkan</span>
                            </div>
                            <div className="time mt-3 mb-3">
                                <div>
                                    <span style={{fontSize: 40, fontWeight: 'bold'}}>{ moment().format('HH : mm : ss') } WIB</span>                                    
                                </div>
                                { moment().format('D MMMM YYYY') }
                            </div>
                        </div>
                    </div>  
                </div>

                <div className="welcome-screen-footer">
                    <div className="footer-content">
                        <div className="row h-100 w-100" style={{marginRight: 0}}>
                            <div className="col-4 footer-hover" onClick={() => navigate(`/${username}/my-account`)}>
                                <div className="child">
                                    <div className="me-3">
                                        <img src={ account_icon } alt="" height={64} />
                                    </div>
                                    <div>
                                        <span className="child-title" style={{letterSpacing: 2}}><span className="br">MY</span> ACCOUNT</span>
                                        <span className="child-subtitle">Informasi tentang personal data <br /> dan akses sistem</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 footer-menu-center">
                                <div onClick={goApp} className="child">
                                    <div className="me-3">
                                        <img src={ rocket_icon } alt="" height={64} />
                                    </div>
                                    <div>
                                        <span className="child-title" style={{letterSpacing: 2}}>GO TO APLIKASI</span>
                                        <span className="child-subtitle">
                                            Pergi ke aplikasi untuk membantu <br /> 
                                            menajalankan aktivitas kerja
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 footer-hover" onClick={() => navigate(`/${username}/general-information`)}>
                                <div className="child">
                                    <div className="me-3">
                                        <img src={ setting_icon } alt="" height={64} />
                                    </div>
                                    <div>
                                        <span className="child-title" style={{letterSpacing: 2}}>GENERAL INFORMASI</span>
                                        <span className="child-subtitle">
                                            Informasi tentang aplikasi dan tutorial <br /> cara untuk mengoperasikan aplikasi
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center-bg">
                    <div className="color-bg">
                        <div className="color-bg-transparent">
                            
                        </div>
                    </div>
                </div>
            </div>
        </Verified>
    )
}

export default ScreenWelcome
