import ArticleController from "../../controller/article/ArticleController"
import BidangController from "../../controller/bidang/BidangController"
import KpiIndicatorController from "../../controller/kpi/KpiIndicatorController"
import KpiScoreController from "../../controller/kpi/KpiScoreController"
import KpiVariableController from "../../controller/kpi/KpiVariableController"
import RkapController from "../../controller/rkap/RkapController"
import UserController from "../../controller/user/UserController"

const _direksi = [
    // bidang
    { path: 'bidang/form', element: BidangController, view: 'form' },
    { path: 'bidang/data', element: BidangController, view: 'data' },
    { path: 'bidang/data/:code', element: BidangController, view: 'detail' },

    // kpi indicator
    { path: 'kpi/indicator/form', element: KpiIndicatorController, view: 'form' },
    { path: 'kpi/indicator/data', element: KpiIndicatorController, view: 'data' },
    { path: 'kpi/indicator/data/:code', element: KpiIndicatorController, view: 'detail' },

    // kpi variable
    { path: 'kpi/variable/form', element: KpiVariableController, view: 'form' },
    { path: 'kpi/variable/data', element: KpiVariableController, view: 'data' },
    { path: 'kpi/variable/edit', element: KpiVariableController, view: 'edit' },

    // kpi score
    { path: 'kpi/score/form', element: KpiScoreController, view: 'form' },
    { path: 'kpi/score/data', element: KpiScoreController, view: 'data' },
    { path: 'kpi/score/data/:code', element: KpiScoreController, view: 'detail' },

     // users
    { path: 'user/form', element: UserController, view: 'form' },
    { path: 'user/data', element: UserController, view: 'data' },
    { path: 'user/data/:code', element: UserController, view: 'detail' },

    // rkap
    { path: 'rkap/form', element: RkapController, view: 'form' },
    { path: 'rkap/data', element: RkapController, view: 'data' },

    // article note
    { path: 'article', element: ArticleController, view: 'article' },
    { path: 'article/:code', element: ArticleController, view: 'detail' },
]   

export default _direksi