import { combineReducers } from "@reduxjs/toolkit";
import bidang_m from "./bidang/bidang_m";
import kpi_indicator_m from "./kpi/kpi_indicator_m";
import kpi_variable_m from "./kpi/kpi_variable_m";
import kpi_score_m from "./kpi/kpi_score_m";
import rkap_m from "./rkap/rkap_m";
import profile_m from "./profile/profile_m";
import note_m from "./note/note_m";
import login_m from "./login/login_m";

const _routes = combineReducers({
    // login
    login : login_m,

    // bidang
    bidang : bidang_m,

    // profile
    profile : profile_m,

    // kpi indicator
    kpi_indicator : kpi_indicator_m,

    // kpi variable
    kpi_variable : kpi_variable_m,

    // kpi score
    kpi_score : kpi_score_m,

    // rkap
    rkap : rkap_m,

    // notes
    note : note_m,
})

export default _routes