import React, { useEffect, useState } from 'react'
import Bidang from '../Bidang'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getBidang } from '../../../model/bidang/bidang_m'
import Moment from 'react-moment'
import { Button } from '@mui/material'
import { PageData } from '../../../utils'

const BidangData = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [bidang, setBidang] = useState([])
    const [pagination, setPagination] = useState([])

    const getData = async(page) => {
        try {
            const response = await dispatch(getBidang(page)).unwrap().catch((err) => {})
            setBidang(response.data)
            setPagination(response.pagination)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getData(1)
    }, [])

    const handlePagination = (_, page) => {
        return getData(page)
    }

    const onDetail = (code) => {
        return navigate(`${code}`)
    }

    return (
        <Bidang>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3>DATA MASTER BIDANG</h3>
                            <span>List Data Master Bidang</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <div className="table-responsive mb-3">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                        <span className="form-control fw-bold">No</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Kode Bidang</span>
                                    </th>
                                    <th style={{width: '35%'}}>
                                        <span className="form-control fw-bold">Nama Bidang</span>
                                    </th>
                                    <th style={{width: '20%'}}>
                                        <span className="form-control fw-bold">Dibuat Pada</span>
                                    </th>
                                    <th style={{width: '30%'}}>
                                        <span className="form-control fw-bold">Keterangan</span>
                                    </th>
                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Action</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    bidang.length > 0 ?
                                    bidang.map((data) => (
                                        <tr key={data.no}>
                                            <td className="text-center">
                                                { data.no > 9 ? data.no : `0${data.no}` }
                                            </td>
                                            <td>{ data.code }</td>
                                            <td>{ data.name }</td>
                                            <td>
                                                <Moment format="dddd, DD MMMM YYYY">{ data.date }</Moment>
                                            </td>
                                            <td>{ data.desc ? data.desc : <i>Tidak Ada Keterangan</i> }</td>
                                            <td className="text-center">
                                                <Button size="small" variant="contained" color="success" onClick={() => onDetail(data.code)}>
                                                    Detail
                                                </Button>
                                            </td>
                                        </tr>   
                                    )) : 
                                    <tr>
                                        <th colSpan={6} className="text-center">DATA TIDAK DITEMUKAN</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* pagination */}
                    <PageData 
                        name={'Data Bidang'}
                        status={pagination.status} 
                        total={pagination.total} 
                        page={pagination.page} 
                        per_page={pagination.per_page} 
                        onChange={handlePagination}
                    />
                </div>
            </div>
        </Bidang>
    )
}

export default BidangData
