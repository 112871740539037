import React, { useEffect, useState } from 'react'
import KpiScore from '../KpiScore'
import { useDispatch } from 'react-redux'
import { getKpiScore } from '../../../../model/kpi/kpi_score_m';
import { Button } from '@mui/material';
import { PageData } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { decode } from 'base-64';

const KpiScoreData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const type = localStorage.getItem('type');

    const [score, setScore] = useState([])
    const [pagination, setPagination] = useState([])

    const getData = async(page) => {
        try {
            const response = await dispatch(getKpiScore(page)).unwrap().catch((err) => {})
            setScore(response.data)
            setPagination(response.pagination)
        } catch (error) {
            
        }
    }


    useEffect(() => {
        getData(1)
    }, [])

    const handlePagination = (_, page) => {
        return getData(page)
    }

    const onDetail = (code) => {
        return navigate(`${code}`)
    }

    return (
        <KpiScore>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{lineHeight: 0}} className="mb-1">
                            <h3 className="text-uppercase">DATA KPI Skor</h3>
                            <span>List Data KPI Skor</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <div className="table-responsive mb-3">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th className="table-border-start text-center" style={{width: '5%'}}>
                                        <span className="form-control fw-bold">No</span>
                                    </th>
                                    <th>
                                        <span className="form-control fw-bold">Bidang</span>
                                    </th>
                                    <th className="text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Grade A</span>
                                    </th>
                                    <th className="text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Grade B</span>
                                    </th>
                                    <th className="text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Grade C</span>
                                    </th>
                                    <th className="text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Grade D</span>
                                    </th>
                                    <th className="text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Grade E</span>
                                    </th>
                                    <th className="table-border-end text-center" style={{width: '10%'}}>
                                        <span className="form-control fw-bold">Action</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    score.length > 0? 
                                    score.map((data) => (
                                        <tr key={data.no}>
                                            <td className="text-center">
                                                {  data.no > 9 ? data.no : `0${data.no}`}
                                            </td>
                                            <td>{ data.bidang }</td>
                                            <td className="text-center">{ data.grade_a }% - 100%</td>
                                            <td className="text-center">
                                                { data.grade_b }% - { data.grade_a - 1 }%
                                            </td>
                                            <td className="text-center">
                                                { data.grade_c }% - { data.grade_b - 1 }%
                                            </td>
                                            <td className="text-center">
                                                { data.grade_d }% - { data.grade_c - 1 }%
                                            </td>
                                            <td className="text-center">
                                                { data.grade_e }% - { data.grade_d - 1 }%
                                            </td>
                                            <td className="text-center">
                                                <Button disabled={  decode(JSON.parse(type)) === 'direksi' || decode(JSON.parse(type)) === 'admin' ? false : true} onClick={() => onDetail(data.code)} size="small" variant="contained" color="success">
                                                    Detail
                                                </Button>
                                            </td>
                                        </tr>
                                    )) : 
                                    <tr>
                                        <th colSpan={8} className="text-center">DATA TIDAK DITEMUKAN</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* pagination */}
                    <PageData 
                        status={pagination.status}
                        name={'KPI Skor'}
                        total={pagination.total}
                        page={pagination.page}
                        per_page={pagination.per_page}
                        onChange={handlePagination}
                    />
                </div>
            </div>
        </KpiScore>
    )
}

export default KpiScoreData
